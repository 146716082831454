import React, { useEffect } from 'react'
import '../../lib/components/auth/style/auth_style.css'
import Home from '../frame/home'
import { Container, Row, Col, Image , Form } from 'react-bootstrap'
import Footer from '../frame/footer-skilmeo'
import { useState } from 'react'
import { apiGetUserListAll } from '../../services/api/admin.js'
import { apiGetImagePromiseProfile } from '../../services/api/certificate.js'
import { apiGetProfile } from '../../services/api/profile.js'
import { apiGetProfileTemplatePrivacy, apiUpdateProfileTemplatePrivacy } from '../../lib/services/api/user.js'

const Settings = () => {
	const switchLabel1 = 'เช่น วันเกิด, อีเมล ฯลฯ'
	const switchLabel2 = 'เช่น บ้านเลขที่, ถนน, จังหวัด'
	const switchLabel3 = 'มัธยม, ปริญญาตรี, ปริญญาโท'
	const [userRole , setUserRole] = useState({	role :'' })
	const [imageSrc, setImageSrc] = useState([]);
	const [profileInfo, setProfileInfo] = useState([])
	const [profilePrivacy , setProfilePrivacy] = useState([])
	const userinfo = localStorage.getItem("portfolio_user_info")
	const anotherpiccondition = JSON.parse(localStorage.getItem('user_info'))


    const fetchData = async () => {
		try {
			let profile = await apiGetProfile()
			let privacy = await apiGetProfileTemplatePrivacy()
			
			const profileItem = profile.data.find((item) => item.profile_template.template_name === 'profile')
			let findsrc = profileItem.data?.find((item) => item.name === 'user_pic')
			let res = await apiGetImagePromiseProfile(findsrc?.value)
			setProfilePrivacy(privacy.data)
			setImageSrc(res)
			setProfileInfo(profileItem?.data)
			
			const storedUserData = JSON.parse(userinfo);

			if (storedUserData.role_id === 1) {
			    let user = await apiGetUserListAll(); 
				if (user.status === 200){			
					setUserRole({ role : "Admin"	})
				}
		    } else {
				setUserRole({ role : "Student" })
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	useEffect(() => {
    	fetchData()
	}, [])

	const getProfileByName = (name) => {
		const item = profileInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

	const getProfilePrivacyByName = (name) => {
		const item = profilePrivacy?.find((item) => item.template_name === name)
		return item ? item.user_privacy?.privacy.can_access : ''
	}

	let profileData = {
		firstName: getProfileByName('first_name'),
		lastName: getProfileByName('last_name'),
		email: getProfileByName('email'),
		workplace: getProfileByName('workplace'),
		agency: getProfileByName('agency'),
	}

	const privacyData = {
		basic_information: getProfilePrivacyByName('basic_information'),
		address: getProfilePrivacyByName('address'),
		education: getProfilePrivacyByName('education'),
		personal_goal: getProfilePrivacyByName('personal_goal'),
		interest: getProfilePrivacyByName('interest'),
	}	
	
	const handleSwitchChange = async (event) => {
		let nam = event.target.name
		let val = event.target.checked
		
		const TemplateId = profilePrivacy?.find((item) => item.template_name === nam)?.id

		let payload =[
			{
				"profile_template_id": TemplateId,
				"privacy" : {
					"can_access" : val
				}
			}
		]

		const res = await apiUpdateProfileTemplatePrivacy(payload)
	
		fetchData()
		
	};

	const renderSwitchItem = (label,switchlabel, name, checked) => (
		<div className='setting-item'>
		  <h4 className='text-color-6'>{label}</h4>
		  <div className="switch-container">
			<label className="switch-label">{switchlabel}</label>
			<Form.Check
			  type="switch"
			  id={name}
			  name={name}
			  checked={checked}
			  onChange={handleSwitchChange}
			/>
		  </div>
		</div>
	);

	return (
		<>
			<Home />
			<Container className='bg-gray' fluid>
				<Row>
					<Col className='text-center'>
						{imageSrc !== null && imageSrc.length !== 0 ? (
							<Image src={imageSrc} width={300}  height={300} className='border-avatar_' alt='' roundedCircle />
							) : (anotherpiccondition?.title === "Ms." ? (
							<Image src='/user_girl.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
							) : (	
							<Image src='/user_boy.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
						))}
					</Col>
					<Col className='profile-group'>
						<div className='border-warning  border-bottom mt-3'>
							<p className='setting-profile-text'>ชื่อ : {profileData.firstName}{" "}{profileData.lastName}</p>
						    <p className='setting-profile-text'>บทบาท : {userRole.role}</p>
						 
						</div>
						<div className='d-flex flex-row mt-4'>
							<div className='d-flex flex-row mr-4'>
								<Image src='/deactivate_account.png'  width={150} fluid/>
								<h5 className='delete-account-text ml-2'>ปิดบัญชีผู้ใช้งาน</h5>
							</div>
							<div className='d-flex flex-row'>
								<Image src='/delete_account.png'  width={150} fluid/>
								<h5 className='delete-account-text ml-2'>ลบบัญชีผู้ใช้งาน</h5>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<Container className='d-flex flex-column justify-content-left bg-image ' fluid>
				<Row >
					<div className=' mt-3 ' >
						<h5 className='setting-title text-color-5'>ตั้งค่าความเป็นส่วนตัว</h5>
						<p className="setting-title ">เลือกข้อมูลที่จะแสดงสาธารณะเมื่อมีผู้เข้าถึงบัญชีของคุณ</p>
					</div>	
					<div className='setting-line border-warning mt-2'></div>
				</Row>
				<Col className='setting-group mt-2 ' >
				<Form>
					{renderSwitchItem("ข้อมูลพื้นฐาน",switchLabel1, "basic_information", privacyData.basic_information)}
					{renderSwitchItem("ข้อมูลที่อยู่",switchLabel2, "address", privacyData.address)}
					{renderSwitchItem("ข้อมูลประวัติการศึกษา",switchLabel3, "education", privacyData.education)}
					{renderSwitchItem("ข้อมูลเป้าหมายส่วนตัว","", "personal_goal", privacyData.personal_goal)}
					{renderSwitchItem("ข้อมูลความสนใจ", "","interest", privacyData.interest)}
				</Form>
				</Col>
			</Container> 
			<Footer/>
		</>
	)
}

export default Settings
