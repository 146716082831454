import '../../lib/components/frame/style/header.css'
import HeaderTop from '../../lib/components/frame/header'
import Mainmenu from '../pages/common/mainmenu'

export default function Home() {
	return (
		<>
			<HeaderTop showProfile='true'></HeaderTop>
			<Mainmenu></Mainmenu>
		</>
	)
}
