import instance from "../../lib/config/skillmeoaxios";

const apiGetProfile = async () => {
    try {
        const result = await instance.get(`/user/profile`, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiGetProfileTemplate = async () => {
    try {
        const result = await instance.get(`/user/profile/template_privacy`, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiAddProfile = async (enteredInfo) => {
    try {
        const result = await instance.post(`/user/profile`, enteredInfo, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiDeleteProfile = async (profileid) => {
    try {
        const result = await instance.delete(`/user/profile/${profileid}`, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiSaveProfile = async (addressId, enteredInfo) => {
    try {
        const result = await instance.put(`/user/profile/${addressId}`, enteredInfo, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

export { apiGetProfile, apiSaveProfile, apiAddProfile, apiGetProfileTemplate, apiDeleteProfile };
