import './../../App.css'
import './main_style.css'
import { Button, Dropdown } from 'react-bootstrap'
import Home from '../frame/home'

function SkillChart() {
	return (
		<>
			<Home />
			<div className='content-grid'>
				<div className='column-content'>
					<div>
						<div className='btn-create'>
							<Button variant='success'>Create New Skill Chart</Button>
							<div
								style={{
									display: 'flex',
									marginLeft: 'auto',
									paddingRight: '30px',
								}}>
								<Dropdown className='dropdown'>
									<Dropdown.Toggle variant='success' id='activity-dropdown'>
										Rublic
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
										<Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
										<Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<Dropdown className='dropdown'>
									<Dropdown.Toggle variant='success' id='sort-by-dropdown'>
										Skill
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
										<Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
										<Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<Dropdown className='dropdown'>
									<Dropdown.Toggle variant='success' id='activity-dropdown'>
										Sort by
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
										<Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
										<Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
					<div className='item-content'>
						<div className='item-1'>Item 1</div>
						<div className='item-2'>Item 2</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SkillChart
