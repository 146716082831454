import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Nav, Image, Row, Col, Container } from 'react-bootstrap'
import '../main_style.css'
import '../../../lib/components/frame/style/header.css'
import { FaUsers } from "react-icons/fa6";

function Mainmenu() {
	const [Title, setTitle] = useState('')
	const [SecTitle, setSecTitle] = useState('')
	const [menuItems, setMenuItems] = useState([]);
	const location = useLocation()
	const [loading, setLoading] = useState(true); 

	useEffect(() => {
        const fetchData = async () => {
			const userInfoString = await new Promise(resolve => {
				resolve(localStorage.getItem('portfolio_user_info'));
			});
            const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
			
            // Set menu items based on user role
            const newMenuItems = userInfo && userInfo.role_id === 1 ? [
                { title: 'ข้อมูลผู้ใช้งาน', path: '/infoboard', image: '/user_profile.png' },
                { title: 'Course Certificate', path: '/course-certificate', image: '/course_certificate.png' },
                { title: 'ตั้งค่าข้อมูลการใช้งาน', path: '/setting', image: '/profile_setting.png' },
                { title: 'จัดการผู้ใช้งาน', path: '/admin-user' },
            ] : [
                { title: 'ข้อมูลผู้ใช้งาน', path: '/infoboard', image: '/user_profile.png' },
                { title: 'Course Certificate', path: '/course-certificate', image: '/course_certificate.png' },
                { title: 'ตั้งค่าข้อมูลการใช้งาน', path: '/setting', image: '/profile_setting.png' },
            ];

            setMenuItems(newMenuItems);

            setLoading(false);
        };

        fetchData();
    }, []);


	useState(() => {
		// Use location.pathname to get the current route
		const currentRoute = location.pathname;
	
		// Set title and secondary title based on the current route
		if (currentRoute === '/infoboard' || currentRoute === '/') {
		  setTitle('ยินดีต้อนรับเข้าสู่ระบบ BLH-PORTFOLIO');
		  setSecTitle('ข้อมูลผู้ใช้งาน');
		} else if (currentRoute === '/course-certificate') {
		  setTitle('ประกาศนียบัตร');
		  setSecTitle('ข้อมูลใบประกาศนียบัตร');
		} else if (currentRoute === '/setting') {
		  setTitle('ตั้งค่าการใช้งาน');
		  setSecTitle('จัดการข้อมูลการใช้งาน');
		} else if (currentRoute === '/admin-user') {
		  setTitle('จัดการผู้ใช้งาน');
		  setSecTitle('จัดการข้อมูลการใช้งาน');
		}
		setLoading(false);
	  }, [location]);
	
	return (
		<>
			<Container className='justify-content-center header-second' fluid>
				<Row className='text-center align-items-center'>
				<Col>
					<div className="text-start">
					<h5 style={{ color: '#6d5046' }}>{Title}</h5>
					<span style={{ color: '#b68d76' }}>{SecTitle}</span>
					</div>
				</Col>
				{loading ? (
                        // Placeholder Col components to maintain layout
                        <>
                            <Col style={{ visibility: 'hidden' ,height:'100px'}}></Col>
                            <Col style={{ visibility: 'hidden' ,height:'100px' }}></Col>
                        </>
                    ) : (
                        // Render menu items
                        menuItems.map((item, index) => (
                            <Col key={index}>
                                <Nav.Link as={NavLink} to={item.path}>
                                    {item.image ? (
                                        <Image src={item.image} width={80} alt="" fluid />
                                    ) : (
                                        <FaUsers className='fa-users'/>
                                    )}
                                    <span>{item.title}</span>
                                </Nav.Link>
                            </Col>
                        ))
                    )}
				</Row>
                   
			</Container>
		</>
	)
}

export default Mainmenu
