import React, { useState, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { apiDeleteProfile, apiAddProfile, apiSaveProfile } from '../../../services/api/profile'
import { BiSolidMessageSquareAdd } from 'react-icons/bi'
import { Image } from 'primereact/image'
import { Tooltip } from 'primereact/tooltip'
import { Toast } from 'primereact/toast'
import { ConfirmDialog } from 'primereact/confirmdialog'

function InterestsModal(data) {
	const [AddDialogVisible, setAddDialogVisible] = useState(false)
	const [visible, setVisible] = useState(false)
	const [interestData, setInterestData] = useState([])
	const [formData, setFormData] = useState({ interest: '' })
	const toast = useRef(null)
	const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
	const [profileToDelete, setProfileToDelete] = useState(null)
	let search = data.profileTemplate.find((item) => item.template_name === 'interest')
	useEffect(() => {
		return () => {
			const dataTable = data.interestInfo.map((item) => ({
				id: item.id,
				profile_template: item.profile_template,
				template_name: item.profile_template.template_name,
				interest: item.data.find((dataItem) => dataItem.name === 'interest')?.value || '',
				index: item.index,
				created_at: item.created_at,
				updated_at: item.updated_at,
				deleted_at: item.deleted_at,
			}))
			setInterestData(dataTable)
		}
	}, [data.interestInfo, data.profileTemplate])

	const updateInterestData = (updatedData) => {
		setInterestData(updatedData)
	}

	const handleConfirmation = async () => {
		const templateId = search.id

		if (!formData.interest) {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Please fill in all required fields.',
				life: 3000,
			})

			return
		}

		const addInfo = {
			profile_template_id: templateId,
			data: [
				{
					name: 'interest',
					value: formData.interest,
				},
			],
		}
		let response = await apiAddProfile(addInfo)

		if (response.status === 201 || response.status === 200) {
			console.log('Successfully added/edited:', response)
			setFormData({
				interest: '',
			})

			toast.current.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Added Successfully',
				life: 3000,
			})
			data.count()
			setAddDialogVisible(false)
		} else {
			console.error('Error:', response)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: response,
				life: 3000,
			})
		}
	}
	const onRowEditComplete = async (e) => {
		const isEditAction = e.data !== e.newData
		if (isEditAction) {
			const updateInfo = {
				data: [
					{
						name: 'interest',
						value: e.newData.interest,
					},
				],
			}
			await apiSaveProfile(e.data.id, updateInfo)
				.then((response) => {
					if (response.status === 200) {
						console.log('Saved successfully:', response)
						const updatedData = interestData.map((row) => {
							if (row.id === e.data.id) {
								return {
									...row,
									interest: e.newData.interest,
								}
							}
							return row
						})

						updateInterestData(updatedData)

						toast.current.show({
							severity: 'success',
							summary: 'Success',
							detail: 'Edit successfully',
							life: 3000,
						})
						data.count()
					}
				})
				.catch((error) => {
					console.error('Error while saving:', error)
					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: 'error',
						life: 3000,
					})
				})
		}
	}
	const textEditor = (options) => {
		return <InputText type='text' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon='pi pi-trash' rounded outlined severity='danger' onClick={() => handleDelete(rowData.id)} />
			</React.Fragment>
		)
	}
	const handleDelete = (profileId) => {
		setProfileToDelete(profileId)
		setConfirmDialogVisible(true)
	}
	const confirmDelete = async () => {
		try {
			await apiDeleteProfile(profileToDelete)
			toast.current.show({
				severity: 'success',
				summary: 'Deleted',
				detail: 'Deleted',
				life: 3000,
			})

			const updatedData = interestData.filter((item) => item.id !== profileToDelete)
			updateInterestData(updatedData)
			data.count()
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'error',
				life: 3000,
			})
		} finally {
			setConfirmDialogVisible(false)
			setProfileToDelete(null)
		}
	}

	const addModal = async () => {
		setAddDialogVisible(true)
	}

	return (
		<>
			<Tooltip target='.insterest-image' />
			<Image
				src='/interest.png'
				onClick={() => setVisible(true)}
				className='insterest-image text-color-1 fs-3'
				style={{ cursor: 'pointer' }}
				width={150}
				alt=''
				data-pr-tooltip='Click to Edit'
				data-pr-position='right'
				data-pr-at='right+5 top'
				data-pr-my='left center-2'
			/>
			<Dialog
				header='ความสนใจ'
				visible={visible}
				onHide={() => setVisible(false)}
				style={{ width: '80vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				headerClassName=' border-bottom black bg-image'>
				<div className='card p-fluid'>
					<DataTable value={interestData} editMode='row' dataKey='id' onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
						<Column key={'interest'} field={'interest'} header={'Interest'} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
						<Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
						<Column body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
					</DataTable>
					<div>
						<span onClick={addModal} className='cursor-pointer'>
							<BiSolidMessageSquareAdd className='pi pi-plus text-color-1 fs-5' /> Add
						</span>
					</div>
				</div>
			</Dialog>
			<Dialog
				header='เพิ่มความสนใจ'
				headerClassName='header-add-edu  border-bottom black bg-image'
				headerStyle={{ borderBottom: '1px solid black' }}
				visible={AddDialogVisible}
				onHide={() => setAddDialogVisible(false)}
				style={{ width: '40vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				footer={
					<div className='button-group'>
						<Button
							label='เพิ่ม'
							className='p-button-text add-edu-confirm'
							onClick={() => {
								handleConfirmation() // Call your confirmation logic
								setAddDialogVisible(false) // Close the dialog
							}}
						/>
						<Button
							label='ยกเลิก'
							className='p-button-text add-edu-cancel'
							onClick={() => {
								setAddDialogVisible(false)
								setFormData({
									schoolName: '',
									degreeType: '',
									fieldOfStudy: '',
									datePeriodFrom: '',
									datePeriodTo: '',
								}) // Clear the form fields
							}}
						/>
					</div>
				}>
				<div className='p-fluid add-edu'>
					<div className='p-field'>
						<label htmlFor='interest'>interest:</label>
						<InputText id='interest-info' name='interest' value={formData.interest} onChange={(e) => setFormData({ ...formData, interest: e.target.value })} required />
					</div>
				</div>
			</Dialog>
			<ConfirmDialog
				contentClassName='confirm-dialog'
				headerClassName='header-add-edu  border-bottom black bg-image'
				visible={confirmDialogVisible}
				onHide={() => setConfirmDialogVisible(false)}
				message='ท่านแน่ใจหรือไม่ ที่จะลบความสนใจนี้?'
				style={{ width: '30vw' }}
				header='ลบความสนใจ'
				icon='pi pi-exclamation-triangle'
				accept={confirmDelete}
				reject={() => setConfirmDialogVisible(false)}
				position='top'
				footer={
					<div className='button-group mt-3'>
						<Button label='ใช่' icon='pi pi-check' onClick={confirmDelete} className='p-button-text add-edu-confirm' />
						<Button label='ไม่' icon='pi pi-times' onClick={() => setConfirmDialogVisible(false)} className='p-button-text add-edu-cancel' />
					</div>
				}
			/>
			<Toast ref={toast} position='bottom-right'></Toast>
		</>
	)
}

export default InterestsModal
