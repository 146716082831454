import React, { useEffect, useState } from 'react';
import Home from '../../frame/home';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { apiGetProfile } from '../../../services/api/profile';
import { apiGetImagePromiseProfile } from '../../../services/api/certificate';
import { apiGetUserListAll } from '../../../services/api/admin';
import Footer from '../../frame/footer-skilmeo';
import UserTable from '../../../lib/components/auth/users_table';

const UserList = () => {
    const [userRole , setUserRole] = useState({	role :'' })
	const [imageSrc, setImageSrc] = useState([]);
	const [profileInfo, setProfileInfo] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)

	const userinfo = localStorage.getItem("portfolio_user_info")
	const anotherpiccondition = JSON.parse(localStorage.getItem('user_info'))


    const fetchData = async () => {
		try {
			let profile = await apiGetProfile()
			
			const profileItem = profile.data.find((item) => item.profile_template.template_name === 'profile')
			let findsrc = profileItem.data?.find((item) => item.name === 'user_pic')
			let res = await apiGetImagePromiseProfile(findsrc?.value)
			
			setImageSrc(res)
			setProfileInfo(profileItem?.data)
			
			const storedUserData = JSON.parse(userinfo);

			if (storedUserData.role_id === 1) {
                setIsAdmin(true)
			    let user = await apiGetUserListAll(); 
				if (user.status === 200){			
					setUserRole({ role : "Admin"	})
				}
		    } else {
                setIsAdmin(false)
				setUserRole({ role : "Student" })
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	useEffect(() => {
    	fetchData()
	}, [])

	const getProfileByName = (name) => {
		const item = profileInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

	let profileData = {
		firstName: getProfileByName('first_name'),
		lastName: getProfileByName('last_name'),
		email: getProfileByName('email'),
		workplace: getProfileByName('workplace'),
		agency: getProfileByName('agency'),
	}

    return (
        <>
        <Home isAdmin={isAdmin}/>
        <Container className='bg-gray' fluid>
				<Row>
					<Col className='text-center'>
						{imageSrc !== null && imageSrc.length !== 0 ? (
							<Image src={imageSrc} width={300}  height={300} className='border-avatar_' alt='' roundedCircle />
							) : (anotherpiccondition?.title === "Ms." ? (
							<Image src='/user_girl.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
							) : (	
							<Image src='/user_boy.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
						))}
					</Col>
					<Col className='profile-group'>
						<div className='border-warning  border-bottom mt-3'>
							<p className='setting-profile-text'>ชื่อ : {profileData.firstName}{" "}{profileData.lastName}</p>
						    <p className='setting-profile-text'>บทบาท : {userRole.role}</p>
						 
						</div>
						<div className='d-flex flex-row mt-4'>
							<div className='d-flex flex-row mr-4'>
								<Image src='/deactivate_account.png'  width={150} fluid/>
								<h5 className='delete-account-text ml-2'>ปิดบัญชีผู้ใช้งาน</h5>
							</div>
							<div className='d-flex flex-row'>
								<Image src='/delete_account.png'  width={150} fluid/>
								<h5 className='delete-account-text ml-2'>ลบบัญชีผู้ใช้งาน</h5>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
            <UserTable props="Skillmeo"/>
          <Footer />
    </>
    );
}

export default UserList;
