import axios from '../../config/axios'
import axiosskillmeo from '../../config/skillmeoaxios'
import axiosmooc from '../../config/moocaxios'
import { type } from '@testing-library/user-event/dist/type'
// import { data } from 'jquery'

const apiGetUserListMooc = async (page, limit, order_by, option) => {
	try {
		const result = await axiosmooc.get(`/v1/user`, {
			params: {
				page: 1,
				limit: 100,
				order_by: [""],
				search_option: [""],
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetUserById = async (user_id) => {
	try {
		const result = await axios.get(`/v1/user/info/${user_id}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiEnableUser = async (id) => {
	// console.log(id)
	// console.log(localStorage.getItem("token"))
	try {
		const result = await axiosmooc.put(`/v1/user/enable/${id}`, '', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('mooc_token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiDisableUser = async (id) => {
	try {
		const result = await axiosmooc.put(`/v1/user/disable/${id}`, '', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('mooc_token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetAdminRoleList = async () => {
	try {
		const result = await axios.get(`/option/admin/role`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiUpdateRoleMooc = async (id, role) => {
	try {
		const result = await axiosmooc.put(`/v1/user/role/${id}`, role, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('mooc_token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiEditPassword = async (id, password) => {
	try {
		const result = await axios.put(`/v1/user/auth/${id}`, password, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiUpdateUser = async (uid, data) => {
	try {
		const result = await axios.put(`/v1/user/account/${uid}`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiAddUser = async (data) => {
	try {
		const result = await axios.post(`/v1/user`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiAddUserSSO = async (enteredInfo) => {
	try {
		const result = await axios.post(`/admin/user/register`, enteredInfo, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})

		return result
	} catch (error) {
		return error && error.response
	}
}
const apiAddUserSkillmeo = async (enteredInfo) => {
	try {
		const result = await axiosskillmeo.post(`/admin/user`, enteredInfo, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('skillmeo_token'),
			},
		})

		return result
	} catch (error) {
		return error && error.response
	}
}

const apiAddUserMooc = async (enteredInfo) => {
	try {
		const result = await axiosmooc.post(`/v1/user`, enteredInfo, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('mooc_token'),
			},
		})

		return result
	} catch (error) {
		return error && error.response
	}
}

const apiAdminResetPassword = async (payload) => {
	try {
		const result = await axios.put(
			`/v1/user/auth/` + payload.user_id + '/change_password',
			{},
			{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			}
		)
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetUserListSkillmeo = async (page, per_page, created_at) => {
	try {
		const result = await axiosskillmeo.get(`/admin/user`, {
			headers: {
				Authorization: `Bearer ` + localStorage.getItem("skillmeo_token"),
			},
			params: {
				page: page,
				per_page: per_page,
				sort_by: created_at
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiDeleteUserSkillmeo = async (profileid) => {
	try {
		const result = await axiosskillmeo.delete(`/admin/user/${profileid}`)
		return result
	} catch (error) {
		return error && error.response
	}
}

const apiDeleteUserMooc = async (profileid) => {
	try {
		const result = await axiosmooc.delete(`/v1/user/${profileid}`)
		return result
	} catch (error) {
		return error && error.response
	}
}

const apiUpdateRoleSkillmeo = async (userId, enteredRole) => {
	try {
		const result = await axiosskillmeo.put(`/admin/user/${userId}/role`, enteredRole)
		return result
	} catch (error) {
		return error && error.response
	}
}

const apiSuspendSkillmeo = async (userId, boonlean) => {
	try {
		const result = await axiosskillmeo.put(`/admin/user/${userId}/status`,boonlean)
		return result
	} catch (error) {
		return error && error.response
	}
}

const apiAdminGetUserById = async (user_id) => {
	try {
		const result = await axios.get(`/admin/user/${user_id}`, {
			params: {
				load_platform_sync_status: "1",
			},
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetUserList = async (page, per_page, search_keyword, search_school_id) => {
	try {
		const result = await axios.get(`/admin/user/list`, {
			headers: {
				Authorization: `Bearer ` + localStorage.getItem("token"),
			},
			params: {
				page: page,
				per_page: per_page,
				...(search_keyword && { keyword : search_keyword }),
				...(search_school_id && { school_id : search_school_id }),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const getPlatformList = async () => {
	try {
		const result = await axios.get(`/admin/platform-user/platforms`, {
			headers: {
				Authorization: `Bearer ` + localStorage.getItem("token"),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiAdminUpdateUser = async (user_id, data) => {
	const payload = {
		... (typeof data.first_name !== 'undefined' && { first_name: data.first_name }),
		... (typeof data.last_name !== 'undefined' && { last_name: data.last_name }),
		... (typeof data.tel !== 'undefined' && { tel: data.tel }),
		... (typeof data.position !== 'undefined' && { position: data.position }),
		... (typeof data.school_id !== 'undefined' && { school_id: data.school_id }),
		... (typeof data.school_area_id !== 'undefined' && { school_area_id: data.school_area_id }),
		... (typeof data.role_id !== 'undefined' && { role_id: data.role_id }),
		... (typeof data.ssn !== 'undefined' && { ssn: data.ssn }),
		... (typeof data.province_id !== 'undefined' && { province_id: data.province_id }),
		... (typeof data.district_id !== 'undefined' && { district_id: data.district_id }),
		... (typeof data.is_enable !== 'undefined' && { is_enable: data.is_enable }),
	}

	try {
		const result = await axios.put(`/admin/user/update/${user_id}`, payload, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	}

	catch (error) {
		return error && error.response && error.response.data
	}

}

const apiAddPlatformUser = async (data) => {
	try {
		const result = await axios.post(`/admin/platform-user`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiUpdatePlatformUserRole = async (id, data) => {
	try {
		const result = await axios.put(`/admin/platform-user/${id}/role`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiDeletePlatformUser = async (id) => {
	try {
		const result = await axios.delete(`/admin/platform-user/${id}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiDeleteUser = async (id) => {

	try {
		const result = await axios.delete(`/admin/user/${id}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	}
	
	catch (error) {
		return error && error.response && error.response.data
	}

}

const apiGetUserPlatformAccessLog = async (user_id) => {
	try {
		const result = await axios.get(`/admin/platform-user/access-log/${user_id}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiBatchUserVerify = async (data) => {
	try {
		const result = await axios.post(`/admin/platform-user/batch/verify`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiBatchUserSync = async (data) => {
	try {
		const result = await axios.post(`/admin/platform-user/batch/sync`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
		return result
	}
	catch (error) {
		return error && error.response && error.response.data
	}

}


export {
	apiGetUserListMooc,
	apiEnableUser,
	apiDisableUser,
	apiGetAdminRoleList,
	apiUpdateRoleMooc,
	apiEditPassword,
	apiUpdateUser,
	apiAddUser,
	apiGetUserById,
	apiAdminResetPassword,
	apiGetUserListSkillmeo,
	apiDeleteUserSkillmeo,
	apiDeleteUserMooc,
	apiUpdateRoleSkillmeo,
	apiAddUserSSO,
	apiAddUserSkillmeo,
	apiAddUserMooc,
	apiSuspendSkillmeo,
	apiGetUserList,
	getPlatformList,
	apiAdminGetUserById,
	apiAdminUpdateUser,
	apiAddPlatformUser,
	apiUpdatePlatformUserRole,
	apiDeletePlatformUser,
	apiDeleteUser,
	apiGetUserPlatformAccessLog,
	apiBatchUserVerify,
	apiBatchUserSync,
}
