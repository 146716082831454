import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { apiLogin } from '../../services/api/auth'
import { Container, Form, Col, Row, Button, Card, Image, FloatingLabel } from 'react-bootstrap'
import officeRight from '../../assets/images/officeRight.png'
import logo from '../../assets/images/logo.png'
import loginBg from '../../assets/images/skillmeo_image_login.png'

function Login() {
	const [username, setUsername] = useState('aaaaaaaa')
	const [password, setPassword] = useState('bbbb1234')

	const handleLogin = async (e) => {
		e.preventDefault()

		try {
			let payload = {
				username : username,
				password : password
			}
			
			const response = await apiLogin(payload)
			const { token, token_expired_at, refresh_token, refresh_token_expired_at } = response.data.token
			if (response.data.is_completed) {
				localStorage.setItem('skillmeo_token', token)
				localStorage.setItem('skillmeo_expired', token_expired_at)
				localStorage.setItem('skillmeo_refresh_token', refresh_token)
				localStorage.setItem('skillmeo_refresh_token_expired_at', refresh_token_expired_at)
			}

			console.log('Login Successful')

			await Swal.fire({
				icon: 'success',
				text: 'เข้าสู่ระบบสำเร็จ',
				confirmButtonColor: '#28825A',
			})
			
			if (token) {
				window.location.replace('/infoboard')

			}

			
		} catch (error) {
			
			console.error('Login Failed:', error)

			
			await Swal.fire({
				icon: 'error',
				title: 'Login Failed',
				text: 'There was an error during login. Please try again later.',
			})
		}
	}

    if (process.env.REACT_APP_ALLOW_ONLY_SSO == "true") {
        //redirect to SSO login
        window.location.replace(process.env.REACT_APP_MAIN_URL);
    }


	return (
	
		<> {process.env.REACT_APP_ALLOW_ONLY_SSO != "true" &&
			<Container className='container-login bg-image-login'  fluid>
				<Row >
					<Col className="skillmeo-login-bg justify-content-center">
						<Image  src={loginBg} width={700} height={700}  />
					</Col>
					<Col  style={{ textAlign: 'center', paddingTop:"13vh" }}>
						<Card className='align-items-center bg-transparent border-0'>
							<Card.Body >
								<Form className='wrapper' onSubmit={handleLogin}>
									<h2 className='mt-4'>เข้าสู่ระบบ</h2>
									<FloatingLabel
											controlId="floatingInput"
											label="ชื่อผู้ใช้"
											className='my-4 mx-4'
										>
										<Form.Control type='text' value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Username'/>
									</FloatingLabel>	
									<FloatingLabel controlId="floatingPassword" label="รหัสผ่าน" className='my-4 mx-4'>
										<Form.Control type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
									</FloatingLabel>
									<Button type='submit' className='btn-login mt-3 '>เข้าสู่ระบบ</Button>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className='footer-login'>
					<Col className='d-flex align-items-end my-2 mx-2 p-0'>
						<Image src={logo} alt='logo' width={150} className='d-none d-md-block ' style={{ }} fluid />
						<div className='text-login text-color-1 font-h3 ml-3 mb-2' style={{ whiteSpace: 'pre-line' }}>
							ระบบจัดการ Portfolio
							<div className='text-secondary'>Bangkok Learning Hub - Portfolio</div>
						</div>
					</Col>
					<Col className='p-0 mr-3 '>
						<div>
							<Image src={officeRight}  className='footer-image-right d-xl-block float-end mt-5' width={250} fluid />
						</div>
					</Col>
				</Row>
			</Container>
		}
		</>
	)
}

export default Login
