import React, { useState, useEffect, useRef } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable, Column, Button, ConfirmDialog, Toast ,InputSwitch } from 'primereact'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { apiDeleteUserSkillmeo, apiUpdateRoleSkillmeo , apiGetUserListMooc, apiGetUserListSkillmeo, apiSuspendSkillmeo, apiEnableUser, apiDisableUser, apiDeleteUserMooc, apiUpdateRoleMooc, getPlatformList, apiGetUserList, apiAdminUpdateUser, apiDeleteUser, apiGetAdminRoleList } from '../../../lib/services/api/admin'
import NewUserModal from './modal/newusermodal'
import ImportUserModal from './modal/importuser'
import './style/auth_style.css'
import { Spinner } from 'react-bootstrap'

export default function UserTable(props) {
	const [roleUpdateCounter, setRoleUpdateCounter] = useState(0)
	const [deleteCounter, setDeleteCounter] = useState(0)
	const [addUserCounter, setAddUserCounter] = useState(0)
	const [userCount, setUserCount] = useState(0)
	const [users, setUsers] = useState(null)
	const [filters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		number: { value: null, matchMode: FilterMatchMode.EQUALS },
		username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		role: { value: null, matchMode: FilterMatchMode.EQUALS },
	})
	const [loading, setLoading] = useState(true)
	const [rolesOptionSSO, setRolesOptionSSO] = useState(null)
	const [rolesOptionSkillmeo] = useState(['admin', 'student','teacher'])
	const [rolesOptionMooc] = useState(['Administrator', 'Mooc Learner','Mooc Learner (SSO)','Teacher'])
	const [showFilter, setShowFilter] = useState(false)
	const [itemId, setItemId] = useState(null)
	const [selectedRole, setSelectedRole] = useState(null)
	const [displayRoleDialog, setDisplayRoleDialog] = useState(false)
	const toast = useRef(null)
	const [displaySuspendDialog, setDisplaySuspendDialog] = useState(false)
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false)
	const [profileId, setProfileId] = useState(null)
	const [checked, setChecked] = useState(false);
	const firstCondition = props.props === "Skillmeo"
	const secondCondition = props.props === "Mooc"

	const toggleFilter = () => {
		setShowFilter(!showFilter)
	}

	const incrementRoleUpdateCounter = () => {
		setRoleUpdateCounter(roleUpdateCounter + 1)
	}

	const incrementDeleteCounter = () => {
		setDeleteCounter(deleteCounter + 1)
	}

	const incrementAddUserCounter = () => {
		setAddUserCounter(addUserCounter + 1)
	}

	const getSeverity = (status) => {
		switch (status) {
			case 'admin' :
				return 'success'
			case 'student':
				return 'info'
			case 'teacher':
				return 'primary'
			case 'Mooc Learner':
				return 'info'
			case 'Mooc Learner (SSO)':
				return 'primary'
			case 'Administrator':
				return 'success'
			case 'Teacher':
				return 'secondary'	
			case 'System Administrator':
				return 'success'
			case 'ผู้ใช้งานทั่วไป':
				return 'secondary'	
			case 'ผู้สอน' :
				return 'primary'
			case 'ผู้เรียน' :
				return 'info'	
			default:
				return 'danger'
		}
	}

	const getTotalSSO = async () => {
		try {
			const res = await apiGetUserList()
			if (res && res.status === 200) {
				setUserCount(res.data.length)
				apiGetUserList().then((data) => {
					if (data) {
						if (data.status === 200) {
							console.log(data.data)
							const usersData = data.data
							const mappedUsers = usersData.map((item, index) => ({
								id: item.id,
								number: index + 1,
								username: item.signon_method ? item.signon_method[0]?.user_name : '',
								email: item.email,
								fullname: `
							${item.first_name} 
							${item.last_name}`,
								role: item.role ? item.role.role_display_name : 'ผู้ใช้งานทั่วไป' ,
								role_id: item.role ? item.role.id :'',
								is_enable: item.is_enable
							}))
							setUsers(mappedUsers)
						}
						setLoading(false)
					}
				})
			}
		} catch (error) {
			console.error(error)
		}
	}

	const getTotalSkillmeo = async () => {
		try {
			const res = await apiGetUserListSkillmeo()
			if (res && res.status === 200) {
				setUserCount(res.data.total)
				apiGetUserListSkillmeo(1, res.data.total, 'created_at').then((data) => {
					if (data) {
						if (data.status === 200) {
							const usersData = getUsers(data)
							const mappedUsers = usersData.map((item, index) => ({
								id: item.id,
								number: index + 1,
								username: item.username,
								email: item.email,
								fullname: `
							${item.profiles[0]?.data.find((dataItem) => dataItem.name === 'first_name')?.value || ''} 
							${item.profiles[0]?.data.find((dataItem) => dataItem.name === 'last_name')?.value || ''}`,
								role: item.role.name,
								is_suspend: item.is_suspend,
							}))
							setUsers(mappedUsers)
						}
						setLoading(false)
					}
				})
			}
		} catch (error) {
			console.error(error)
		}
	}

	const getTotalMooc = async () => {
		try {
			const res = await apiGetUserListMooc()
			if (res && res.status === 200) {
				setUserCount(res.data.count)
				apiGetUserListMooc().then((data) => {
					if (data) {
						if (data.status === 200) {
							const usersData = getUsers(data)
							const mappedUsers = usersData.map((item, index) => ({
								id: item.id,
								number: index + 1,
								username: item.user_method[0].username || "",
								email: item.email,
								fullname: `
							${item.first_name} 
							${item.last_name}`,
								role: item.user_role[0] ? item.user_role[0].role_name : "",
								is_enable: item.is_enable
							}))
							setUsers(mappedUsers)
						}
						setLoading(false)
					}
				})
			}
		} catch (error) {
			console.error(error)
		}
	}
	
	const getRoleList = async () => {
		try {
			const res = await apiGetAdminRoleList();
			if (res && res.status === 200) {
				const data = res.data;
				const roleDisplayNames = data.map((item) => item.role_display_name);
				setRolesOptionSSO(roleDisplayNames);
				console.log(rolesOptionSSO);
			}
		} catch (error) {
			console.error("Error fetching role list:", error);
		}
	};

	const getDataTable = (props) =>{
		switch(props){
			case 'SSO' : return getTotalSSO()
			case 'Skillmeo' : return getTotalSkillmeo()
			case 'Mooc' : return getTotalMooc()
		}
		
	}

	useEffect(() => {
		const fetchData = async () => {
			 await getDataTable(props.props)
			 await getRoleList()
		}
		fetchData()
	}, [deleteCounter, roleUpdateCounter ,addUserCounter])

	const getUsers = (data) => {
		return [...(data.data.data || [])].map((d) => {
			d.created_at = new Date(d.created_at)

			return d
		})
	}

	const renderHeader = () => {
		return (
			<div className='header-datatable d-flex justify-content-between'>
				<h4 className='text-center mt-4 ml-4 text-color-5'>จำนวนผู้ใช้งานทั้งหมด : {userCount} คน</h4>
				<span className='d-flex mr-4'>
					<NewUserModal addCounter={incrementAddUserCounter} currentApp={props.props} />
					<ImportUserModal addCounter={incrementAddUserCounter} currentApp={props.props}/>
					<Button onClick={toggleFilter} className='btn-user btn-toggle pi pi-search'></Button>
				</span>
			</div>
		)
	}

	const roleBodyTemplate = (rowData) => {
		return	<Tag value={rowData.role} severity={getSeverity(rowData.role)} />
	}

	const roleItemTemplate = (option) => {
		return <Tag value={option} severity={getSeverity(option)} />
	}
	
	let dropdownOptions;
	if (firstCondition) {
	dropdownOptions = rolesOptionSkillmeo;
	} else if (secondCondition) {
	dropdownOptions = rolesOptionMooc;
	} else {
	dropdownOptions = rolesOptionSSO;
	}

	const roleRowFilterTemplate = (options) => {
		return (
			<Dropdown
				value={options.value}
				options={dropdownOptions}
				onChange={(e) => options.filterApplyCallback(e.value)}
				itemTemplate={roleItemTemplate}
				placeholder='Select Role'
				className='p-column-filter'
				showClear
				style={{ minWidth: '12rem' }}
			/>
		)
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Dropdown value={rowData.role} onChange={(e) => showConfirmDialog(rowData.id, e.value)} options={dropdownOptions}  className='mr-2' style={{ width: '9rem' }} />
				<Button icon='pi pi-trash' rounded outlined severity='danger' onClick={() => showDeleteDialog(rowData.id)} />
			</React.Fragment>
		)
	}
	const suspendBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<InputSwitch checked={rowData.is_suspend||rowData.is_enable} onChange={(e) => showSuspendDialog(rowData.id ,e.value)} />
			</React.Fragment>
		)
	}

	const responseStatus = async (detail,response) => {
		if (response.status === 200) {
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: `${detail} Successfully`,
				life: 3000,
			});
		} else {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: `${detail} Fail`,
				life: 3000,
			});
		}
	}
	const showConfirmDialog = (itemId, selectedRole) => {
		setItemId(itemId)
		setSelectedRole(selectedRole)
		setDisplayRoleDialog(true)
	}

	const hideConfirmDialog = () => {
		setDisplayRoleDialog(false)
	}
	
	const handleUpdateRole = async () => {
		hideConfirmDialog();
		
		const roleSSO = {
			'ผู้ใช้งานทั่วไป': 1,'ผู้เรียน':2,'ผู้สอน':3,'ผู้จัดการโรงเรียน':4,'System Administrator':5
		}
		const roleSkillmeo = {
			'admin': 1,
			'student': 2,
			'teacher': 3,
		};
	  
		const roleMooc = {
			'Administrator': [1],
			'Mooc Learner': [6],
			'Mooc Learner (SSO)': [3],
			'Teacher':[4],
		};
		
		const userId = itemId;
		let updateRole;
		let newRoleId;
	  
		try {
		  let newSelectedRole
		  if(firstCondition){
			newSelectedRole = roleSkillmeo
		  } else if (secondCondition){
			newSelectedRole = roleMooc
		  } else {
			newSelectedRole = roleSSO
		  }
		  newRoleId = newSelectedRole[selectedRole];
	  
		  updateRole = {
			role_id: newRoleId,
		  };
		  
		  const apiUpdateRoleFunction = firstCondition ? apiUpdateRoleSkillmeo : (secondCondition ? apiUpdateRoleMooc : apiAdminUpdateUser);
		  const response = await apiUpdateRoleFunction(userId, updateRole);
	  
		  responseStatus('Update Role', response);
		} catch (error) {
		  console.error('An error occurred:', error);
		  toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'Update Role Error',
			life: 3000,
		  });
		}
	  
		incrementRoleUpdateCounter();
	};

	const showSuspendDialog = (itemId, checked) => {
		setItemId(itemId)
		setChecked(checked)
		setDisplaySuspendDialog(true)
	}
	
	const hideSuspendDialog = () => {
		setDisplaySuspendDialog(false)
	}
	const handleSuspend = async () => {
		hideSuspendDialog()

		try {
			const userId = itemId
			if(firstCondition){
				const updateStatus = {
					is_suspend : checked,
				}
				let response = await apiSuspendSkillmeo(userId, updateStatus)
				responseStatus('Update Status',response)
			} else if (secondCondition){
				if(checked === true){
					let response = await apiEnableUser(userId)
					responseStatus('Update Status',response)
				} else {
					let response = await apiDisableUser(userId)
					responseStatus('Update Status',response)
				}
			} else {
				const updateStatus = {
					is_enable : checked,
				}
				let response = await apiAdminUpdateUser(userId, updateStatus)
				responseStatus('Update Status', response)
			}
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: `Update Status Error`,
				life: 3000,
			})
		}

		incrementRoleUpdateCounter()
	}

	const showDeleteDialog = async (profileId) => {
		try {
			setProfileId(profileId)
			setDisplayDeleteDialog(true)
		} catch (error) {
			console.error('An error occurred:', error)
		}
	}

	const handleDelete = async () => {
		hideDeleteDialog()

		try {
			if(firstCondition) {
				let response = await apiDeleteUserSkillmeo(profileId)
				responseStatus('Delete User',response)
			} else if (secondCondition){
				let response = await apiDeleteUserMooc(profileId)
				responseStatus('Delete User',response)
			} else {
				let response = await apiDeleteUser(profileId)
				responseStatus('Delete User',response)
			}
			incrementDeleteCounter()
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: `Delete User Error`,
				life: 3000,
			})
		}
	}

	const hideDeleteDialog = () => {
		setDisplayDeleteDialog(false)
	}

	const header = renderHeader()

	const suspendHeader = (prop) => {
		return prop === "Skillmeo" ? (checked ? 'ระงับผู้ใช้งาน' : 'เปิดผู้ใช้งาน') : (checked ?  'เปิดผู้ใช้งาน': 'ระงับผู้ใช้งาน');
	}

	const suspendMessage = (prop) => {
		return prop === "Skillmeo" ? (checked ? 'ท่านต้องการจะระงับผู้ใช้งานนี้ใช่หรือไม่?':'ท่านต้องการจะเปิดผู้ใช้งานนี้ใช่หรือไม่?') : (checked ? 'ท่านต้องการจะเปิดผู้ใช้งานนี้ใช่หรือไม่?':'ท่านต้องการจะระงับผู้ใช้งานนี้ใช่หรือไม่?');
	}
	
	const renderDataTable = () =>{
		return	<DataTable
					stripedRows
					value={users}
					paginator
					rows={10}
					dataKey='id'
					filters={filters}
					filterDisplay={showFilter ? 'row' : 'none'} // Conditional filter display
					loading={loading}
					globalFilterFields={['number', 'username', 'email', 'fullname', 'role']}
					header={header}
					emptyMessage='No user found.'>
					<Column field='number' header='#' sortable style={{ minWidth: '10rem', width: '7%', textAlign: 'center' }} />
					<Column field='username' header='Username' filter={showFilter} sortable filterPlaceholder='Search by Username' style={{ minWidth: '12rem' }} />
					<Column field='email' header='Email' filter={showFilter} sortable filterPlaceholder='Search by Email' style={{ minWidth: '12rem' }} />
					<Column field='fullname' header='Fullname' filter={showFilter} sortable filterPlaceholder='Search by Name' style={{ minWidth: '14rem' }} />
					<Column
						field='role'
						header='Role'
						showFilterMenu={false}
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
						body={roleBodyTemplate}
						filter={showFilter}
						sortable
						filterElement={roleRowFilterTemplate}
					/>
					{firstCondition ? (
						<Column	field='Suspend'	header='Suspend' body={suspendBodyTemplate}	style={{ minWidth: '7rem' }}/>
						) : (
						<Column	field='Status'	header='Status'	body={suspendBodyTemplate}	style={{ minWidth: '7rem' }}/>
					)}
					<Column field='Actions' header='Actions' body={actionBodyTemplate} style={{ minWidth: '14rem' }} />
				</DataTable>
	}

	const renderRoleDialog = ()=>{
		return <ConfirmDialog
					contentClassName='confirm-dialog'
					headerClassName='header-add-edu  border-bottom black bg-image'
					visible={displayRoleDialog}
					header='เปลี่ยนบทบาทผู้ใช้งาน'
					style={{ width: '30rem' }}
					modal
					message='ท่านต้องการเปลี่ยนบทบาทผู้ใช้งานนี้ใช่หรือไม่?'
					onHide={hideConfirmDialog}
					footer={
						<div>
							<div className='button-group ml-2 mb-2 mt-3 '>
								<Button label='ตกลง' onClick={handleUpdateRole} className='p-button-upload mr-2' />
								<Button label='ยกเลิก' onClick={hideConfirmDialog} className='p-button-secondary' />
							</div>
						</div>
					}
				/>
	}

	const renderSuspendDialog = () =>{
		return <ConfirmDialog
					contentClassName='confirm-dialog'
					headerClassName='header-add-edu  border-bottom black bg-image'
					visible={displaySuspendDialog}
					header={suspendHeader(props.props)}
					style={{ width: '30rem' }}
					modal
					message={suspendMessage(props.props)}
					onHide={hideSuspendDialog}
					footer={
						<div>
							<div className='button-group ml-2 mb-2 mt-3 '>
								<Button label='ตกลง' onClick={handleSuspend} className='p-button-upload mr-2' />
								<Button label='ยกเลิก' onClick={hideSuspendDialog} className='p-button-secondary' />
							</div>
						</div>
					}
				/>
	}

	const renderDeleteDialog = () =>{
		return <ConfirmDialog
					contentClassName='confirm-dialog'
					headerClassName='header-add-edu  border-bottom black bg-image'
					visible={displayDeleteDialog}
					header='ลบผู้ใช้งาน'
					style={{ width: '30rem' }}
					modal
					message='ท่านต้องการลบผู้ใช้งานนี้ใช่หรือไม่?'
					onHide={hideDeleteDialog}
					footer={
						<div>
							<div className='button-group ml-2 mb-2 mt-3 '>
								<Button label='ตกลง' onClick={handleDelete} className='p-button-upload mr-2' />
								<Button label='ยกเลิก' onClick={hideDeleteDialog} className='p-button-secondary' />
							</div>
						</div>
					}
				/>
	}

	return (
		<>
			<div className='card bg-body-teritory header-table'>
				{loading &&	
					<div className='spinner-loading'>
						<Spinner animation='border' variant='success' style={{ width: '4rem', height: '4rem' }} />
					</div> }
				{!loading && renderDataTable()}
				{renderRoleDialog()}
				{renderSuspendDialog()}
				{renderDeleteDialog()}
			</div>
			<Toast ref={toast} position='top-center' />
		</>
	)
}
