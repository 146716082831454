import React, { useState, useRef ,useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputMask } from 'primereact/inputmask';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { apiAddUserMooc, apiAddUserSkillmeo, apiAddUserSSO, apiGetAdminRoleList } from "../../../services/api/admin";
import { apiGetOptionstInfo, apiGetOptionstMooc, apiGetOptionstMoocRole, apiGetOptionstSkillmeo } from "../../../services/api/options";


function NewUserModal({ addCounter, currentApp }) {
	const toast = useRef(null);
	const [visible, setVisible] = useState(false);
	const [selectedSchool, setSelectedSchool] = useState()
	const [selectedRole, setSelectedRole] = useState('')
	const [selectedArea, setSelectedArea] = useState()
	const [selectedProvince, setSelectedProvince] = useState()
	const [selectedDistrictOffice, setSelectedDistrictOffice] = useState()
	
	const [provinceOptions, setProvinceOptions] = useState([])
	const [districtOptions, setDistrictOptions] = useState([])
	const [districtAllOptions, setDistrictAllOptions] = useState([])
	const [roleOptions, setRoleOptions] = useState([])
	const [schoolOptions, setSchoolOptions] = useState([])
	const [areaOption, setAreaOption] = useState([])

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [title, setTitle] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [tel, setTel] = useState('')
	const [cardid, setCardId] = useState('')
	const [position, setPosition] = useState('')

	const firstCondition = currentApp === 'Skillmeo'
	const secondCondition = currentApp ===  'Mooc'

	const titleOption = [
		{
			en: 'Mr.',
			th: 'นาย',
		},
		{
			en: 'Ms.',
			th: 'นาง/นางสาว',
		},
	]

	// Add a function to update district options when a province is selected
	const updateDistrictOptions = (provinceCode) => {
		if (districtAllOptions[provinceCode]) {
			setDistrictOptions(districtAllOptions[provinceCode])
		} else {
			setDistrictOptions([]) // Clear district options if no data is available for the selected province
		}
	}
	const fetchOptionSSO = async () => {
		try {
			let options = await apiGetOptionstInfo()
			if (options && options.status === 200) {
				console.log(options)
				let roles = options.data.roles.map(({ id, role_display_name }) => ({ name: role_display_name, code: parseInt(id) }))
				let schools = options.data.schools.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
				let provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
				let schoolareas = options.data.areas.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))

				setRoleOptions(roles)
				setSchoolOptions(schools)
				setProvinceOptions(provinces)
				setAreaOption(schoolareas)
				setDistrictAllOptions(options.data.districts)
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}
	const fetchOptionSkillmeo = async () => {
		try {
			let options = await apiGetOptionstSkillmeo()
			if (options && options.status === 200) {
				
				let roles = options.data.roles.map(({ id, name }) => ({ name: name, code: parseInt(id) }))
				let schools = options.data.schools.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
				let provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
				let schoolareas = options.data.areas.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))

				setRoleOptions(roles)
				setSchoolOptions(schools)
				setProvinceOptions(provinces)
				setAreaOption(schoolareas)
				setDistrictAllOptions(options.data.districts)
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}
	
	const fetchOptionMooc = async () => {
		try {
			let options = await apiGetOptionstMooc()
			let optionsrole = await apiGetOptionstMoocRole()
			if (options && options.status === 200) {
	
				let roles = optionsrole.data.map(({ id, role_name }) => ({ name: role_name, code: parseInt(id) }))
				let schools = options.data.school_master_data.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
				let provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
				let schoolareas = options.data.school_area_master_data.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))

				setRoleOptions(roles)
				setSchoolOptions(schools)
				setProvinceOptions(provinces)
				setAreaOption(schoolareas)
				setDistrictAllOptions(options.data.districts)

			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}
	useEffect(() => {
		if(firstCondition) {
			fetchOptionSkillmeo()
		} else if(secondCondition) {
			fetchOptionMooc()
		} else {
			fetchOptionSSO()
		}
	}, [])

	const showDialog = () => {
		setVisible(true);
	};

	const hideDialog = () => {
		setVisible(false);
	};

	const responseStatus = async (response) => {
		if	(response.data.is_completed === false){
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: `${response.data.error_message}`,
				life: 3000,
			});
			return
		}
		if (response.status === 200 || response.status === 201) {
			addCounter();
			hideDialog();
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: `Add New User Successfully`,
				life: 3000,
			});
		} else {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: response.data.message,
				life: 3000,
			});
		}
	}

	const createNewUser = async () => {
		if (!username) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: `โปรดกรอก username`,
				life: 3000,
			});
			return;
		}

		const payloadsso = {
			"title" : title.en,
			"first_name" : firstname,
			"last_name" : lastname,
			"email" : email,
			"tel" : tel,
			"user_name" : username,
			"password" : password,
		
			"school_id" : selectedSchool.code,
			"school_area_id" : selectedArea?.code,
			"role_id" : selectedRole?.code,
			"ssn" : cardid,
			"province_id" : selectedProvince?.code,
			"district_id" : selectedDistrictOffice?.district_id,
			"position" : position
		}

		const payloadskillmeo = {
			"username" :username ,
			"password" : password,
			"email" : email,
			"first_name" : firstname,
			"last_name" : lastname,
			"title": title.en,
			"role_id" : selectedRole?.code,
			"profiles" : [
				{
					"profile_template_id": "754719b8-9f7c-437b-a0fd-363e8acae2bd",
					"data": [
						{
							"name": "school_id",
							"value": selectedSchool?.code
						},
						{
							"name": "school_area_id",
							"value": selectedArea?.code
						},
						{
							"name": "ssn",
							"value": cardid
						},
						{
							"name": "district_id",
							"value": selectedDistrictOffice?.district_id
						},
						{
							"name": "province_id",
							"value": selectedProvince?.code
						}
					]
				},
				{
					"profile_template_id": "00c2d8a5-18a7-43c1-94c6-877ab836e202",
					"data": [
						{
							"name": "tel",
							"value": tel
						},{
							"name": "email",
							"value": email
						}
					]
				}
			]
		};

		const payloadmooc = {
			"user" : {
				"title" : title.en,
				"first_name" : firstname,
				"last_name" : lastname,
				"email" : email,
				"tel" : tel,
				"roles" : [selectedRole?.code],
				"organizes" : [1]
			},
			"method" : {
				"method_type" : "basic",
				"username" : username,
				"password" : password
			},
			"user_data" : {
				"school_id" : selectedSchool?.code,
				"school_area_id" : selectedArea?.code,
				"role_id" : selectedRole?.code,
				"ssn" : cardid,
				"province_id" : selectedProvince?.code,
				"district_id" : selectedDistrictOffice?.district_id,
				"position" : position
			}
		}

		let response = null		
		if (firstCondition) {
			response = await apiAddUserSkillmeo(payloadskillmeo);
			console.log(response)
			responseStatus(response)
		} else if (secondCondition) {
			response = await apiAddUserMooc(payloadmooc);
			console.log(response)
			responseStatus(response)
		} else {
			response = await apiAddUserSSO(payloadsso);
			console.log(response)
			responseStatus(response)
		}
	};

	const elementGroupClassName = "flex flex-column gap-2 mb-2";

 	return (
		<div>
			<Toast ref={toast} position="top-center"></Toast>
			<Button label="เพิ่มผู้ใช้งาน" severity="success" className="btn-user" onClick={showDialog} />
			<Dialog
				header="เพิ่มผู้ใช้งานใหม่"
				headerClassName='border-bottom black bg-image'
				visible={visible}
				onHide={hideDialog}
				style={{ width: "600px" }}
				footer={
					<div >
						<div className='button-group ml-2 mb-3 mt-3 '>
							<Button label='เพิ่ม' onClick={createNewUser} className='p-button-upload mr-2' />
							<Button label='ยกเลิก' onClick={hideDialog} className='p-button-secondary' />
						</div>
					</div>
				}
			>
				<form className="my-3 mx-5">
					<div className={elementGroupClassName}>
						<label htmlFor='role'>Role</label>
						<Dropdown
							value={selectedRole}
							onChange={(e) => setSelectedRole(e.value)}
							options={roleOptions}
							optionLabel='name'
							placeholder='เลือก Role'
							className='w-full'
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor="username" style={{ textAlign: "left" }}>
							ชื่อผู้ใช้งาน:
						</label>
						<InputText
							id="username_admin"
							name="username"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='password'>รหัสผ่าน</label>
						<Password id='password' aria-describedby='password-help' onChange={(e) => setPassword(e.target.value)} required />
						<small id='password-help'>รหัสผ่านต้องมีความยาว 8 ตัวอักษร</small>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor="email" style={{ textAlign: "left" }}>
						อีเมล:
						</label>
						<InputText
							id="email_admin"
							name="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='title'>คำนำหน้า</label>
						<Dropdown id='title' value={title} options={titleOption} optionLabel='th' onChange={(e) => setTitle(e.target.value)} required />
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor="firstname" style={{ textAlign: "top" }}>
							ชื่อจริง:
						</label>
						<InputText
							id="firstname_admin"
							name="firstname"
							placeholder="First Name"
							value={firstname}
							onChange={(e) => setFirstname(e.target.value)}
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor="lastname" style={{ textAlign: "top" }}>
							นามสกุล:
						</label>
						<InputText
							id="lastname_admin"
							name="lastname"
							placeholder="Last Name"
							value={lastname}
							onChange={(e) => setLastname(e.target.value)}
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='card_id'>หมายเลขบัตรประชาชน</label>
						<InputMask id='card_id' keyfilter='int' onChange={(e) => setCardId(e.target.value)} mask='9999999999999' required />
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor="lastname" style={{ textAlign: "top" }}>
							เบอร์โทรศัพท์:
						</label>
						<InputText
							id="tel_admin"
							name="tel"
							placeholder="Tel"
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='province'>จังหวัด</label>
						<Dropdown
							value={selectedProvince}
							onChange={(e) => {
								setSelectedProvince(e.value)
								updateDistrictOptions(e.value.code) // Update district options based on the selected province
							}}
							options={provinceOptions}
							optionLabel='name'
							placeholder='เลือก จังหวัด'
							className='w-full'
							filter
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='school_name'>โรงเรียน</label>
						<Dropdown
							value={selectedSchool}
							onChange={(e) => setSelectedSchool(e.value)}
							options={schoolOptions}
							optionLabel='name'
							filter
							filterBy='name'
							placeholder='เลือก รหัส-ชื่อโรงเรียน'
							virtualScrollerOptions={{ itemSize: 25 }}
							className='w-full'
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='district_office'>สำนักงานเขต</label>
						<Dropdown
							value={selectedArea}
							onChange={(e) => setSelectedArea(e.target.value)}
							options={areaOption}
							optionLabel='name'
							placeholder='เลือก สำนักงานเขต'
							className='w-full'
							filter
							required
						/>
					</div>
					<div className={elementGroupClassName}>
						<label htmlFor='district'>เขต/อำเภอ</label>
						<Dropdown
							value={selectedDistrictOffice}
							onChange={(e) => setSelectedDistrictOffice(e.value)}
							options={districtOptions}
							optionLabel='district_name'
							placeholder='เลือก เขต/อำเภอ'
							virtualScrollerOptions={{ itemSize: 25 }}
							className='w-full'
							filter
							required
						/>
					</div>
					{currentApp === "Mooc" && (
						<div className={elementGroupClassName}>
							<label htmlFor='position'>ตำแหน่ง</label>
							<InputText id='position' value={position} onChange={(e) => setPosition(e.target.value)} required />
						</div>
					)}
				</form>
			</Dialog>
		</div>
	);
}

export default NewUserModal;
