import { apiAddCertificate, apiGetCategoryCert, apiUploadFile, } from "../../../services/api/certificate";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import "../main_style.css";
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';

const CertificateModal = ({ incrementCounter }) => {
    const fileUploadRef = useRef(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    const handleFileChange = (event) => {
        const selectedFile = event.files[0];
        setSelectedFile(selectedFile);
    
    };
    const imageUrl = '/add_cert_icon.png';
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await apiGetCategoryCert();
                if (response && response.status === 200) {
                    const fetchedCategories = response.data;
                    setCategoryOptions(fetchedCategories);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
        fetchCategories();
    }, []);

    const handleCategoryChange = (event) => {
        setSelectedCategoryId(event.value.id);
        setSelectedCategoryName(event.value.category_name);
    };

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append(
                "file_access_policy",
                JSON.stringify({ profile_template_id: "00c2d8a5-18a7-43c1-94c6-877ab836e202" })
            );
            if (!selectedCategoryId) { 
                toast.current.show({
                    severity: "error",
                    summary: "Please Select Category",
                    life: 3000,
                    position: "top-center",
                });
                return
            }
            const UploadCertRes = await apiUploadFile(formData);
            
            if (UploadCertRes.status === 201) {
                console.log(UploadCertRes);
                const certificateData = {
                    certificate_category_id: selectedCategoryId,
                    file_id: UploadCertRes.data.file_id,
                };
               
                const certificateResponse = await apiAddCertificate(certificateData);

                if (certificateResponse.status === 201) {
                    incrementCounter();

                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: `Certificate Category ${selectedCategoryName} uploaded successfully`,
                        life: 3000,
                    });

                    fileUploadRef.current.clear();
                    setSelectedCategoryId(null);
                    setSelectedCategoryName(null);
                    setSelectedFile(null);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Certificate Upload Failed",
                        life: 3000,
                        position: "top-center",
                    });
                }
            } else {
                toast.current.show({
                severity: "error",
                summary: "Upload File Failed",
                life: 3000,
                position: "top-center",
                });
            }
        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "An error occurred",
                detail: error.message,
                life: 3000,
                position: "top-center",
            });
        }
    };

    const handleCancel = () => {
        setSelectedFile(null);
        setVisible(false);
    };

    const header = () => {
        return <div className="header-upload-title">อัพโหลด ใบประกาศนียบัตร</div>;
    };
    
    const onRemove = () => {
        setSelectedFile(null);
    };

    
  return (
    <>
        <Tooltip target=".upload-cert-image" />
        <Image 
            src={imageUrl} 
            onClick={() => setVisible(true)} 
            className='upload-cert-image text-color-1 fs-3'
            style={{ cursor: "pointer" }}
            width={150} 
            alt='' 
            data-pr-tooltip="Click to Add New Certificate"  
            data-pr-position="right"
            data-pr-at="right+5 top"
            data-pr-my="left" 
            fluid 
        />
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            style={{
            width: "60vw", 
            maxWidth: "20rem", 
            height: "auto", 
            }}
            headerClassName="custom-dialog border-bottom black bg-image"
            footer={
            <div  className="button-group add-edu ml-3 mb-3 mt-3 ">
                <Button label="อัพโหลด" onClick={handleSave} className="p-button-upload mr-2" />
                <Button label="ยกเลิก" onClick={handleCancel} className="p-button-secondary mr-2" />
            </div>
            }
        >
            <div className="d-flex flex-column justify-content-center my-3 mx-5 align-items-center">
                <Toast ref={toast} position="top-center"></Toast>
                <FileUpload
                    name="fileUpload"
                    accept=".jpg,.svg,.png"
                    chooseLabel="เลือกไฟล์"
                    uploadLabel="Upload"
                    customUpload
                    auto
                    onSelect={handleFileChange}
                    ref={fileUploadRef}
                    onRemove={onRemove}
                    className="file-choose-btn"
                />
                <div className="dropdown-upload-cert">
                    <Dropdown
                        id="categorySelect"
                        value={selectedCategoryId}
                        options={categoryOptions.map((categoryOption) => ({
                            label: categoryOption.category_name,
                            value: { id: categoryOption.id, category_name: categoryOption.category_name },
                        }))}
                        onChange={handleCategoryChange}
                        placeholder={selectedCategoryName ? selectedCategoryName : "เลือกประเภท"}
                        style={{ width: "15rem", display:'flex' ,justifyContent:"center" ,alignItems:"center",marginTop:"20PX"}}
                    />
                </div>
            </div>        
        </Dialog>
    </>
  );
};

export default CertificateModal;
