import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BiEdit } from "react-icons/bi";
import { apiAddProfile, apiGetProfile, apiSaveProfile } from "../../../services/api/profile";
import { Toast } from "primereact/toast";
import { Image } from "react-bootstrap";
import { GrPrevious , GrNext } from "react-icons/gr";
import { TabView, TabPanel } from "primereact/tabview";
import { addLocale } from "primereact/api";
import { apiGetImagePromiseProfile, apiUploadFile } from "../../../services/api/certificate";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from 'primereact/tooltip'
import FileResizer from 'react-image-file-resizer';
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { ThaiDatePicker } from "thaidatepicker-react";
dayjs.extend(buddhistEra)
dayjs.locale('th')


function ProfileModal(data) {
    const fileUploadRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");

    let [formData, setFormData] = useState({
        user_pic: "",
        firstName: "",
        lastName: "",
        email: "",
        workplace: "",
        agency: "",
        tel: "",
        birthday: "",
        address: "",
        city: "",
        country: "",
        postalCode: "",
    });
    
    
    let [updateFormData, setUpdateFormData] = useState({
        user_pic: null,
        firstName: null,
        lastName: null,
        email: null,
        workplace: null,
        agency: null,
        tel: null,
        birthday: null,
        address: null,
        city: null,
        country: null,
        postalCode: null,
    });
    
    addLocale('th', {
        firstDayOfWeek: 0, 
        dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
        dayNamesShort: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
        dayNamesMin: ['อ', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
        monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        today: 'วันนี้',
        clear: 'ล้าง',
    
    })
    let ImagePromise = "";
    const toast = useRef(null);
    let searchProfile = data.profileTemplate.find((item) => item.template_name === "profile");
    let searchAddress = data.profileTemplate.find(item => item.template_name === "address");
    let searchBasic = data.profileTemplate.find(item => item.template_name === "basic_information");
    
    const fetchData = async () => {
        if (data) {
        
            const { address, city, country, postalCode } = data.addressInfo;
            const { birthday, tel } = data.basicInfo;
            const { user_pic, firstName, lastName, email, workplace, agency } = data.profileInfo;
            
            let birthdaystr = dayjs().format("YYYY-MM-DD")
            if(!birthday){
                birthdaystr = dayjs(birthday).toString()
            }
            
            setSelectedDate(birthdaystr)
            setFormData({
                user_pic: user_pic || "",
                firstName: firstName || "",
                lastName: lastName || "",
                email: email || "",
                workplace: workplace || "",
                agency: agency || "",
                tel: tel || "",
                address: address || "",
                city: city || "",
                country: country || "",
                postalCode: postalCode || "",
            });
            
            if(formData.user_pic){
                ImagePromise = await apiGetImagePromiseProfile(formData.user_pic); 
                setImageSrc(ImagePromise)
                
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [data.profileInfo, data.profileTemplate]);

    const minSelectableDate = new Date();
    minSelectableDate.setDate(1);

    useEffect(() => {
        setUpdateFormData({ ...formData });
    }, [formData]);
    const handleDatePickerChange = (christDate) => {
        const formatdate = dayjs(christDate).add(543, 'year')  
        setSelectedDate(christDate);
        setUpdateFormData({ ...updateFormData, birthday:formatdate});
    };
    const openModal = async () => {
        setVisible(true);
    };

    const hideModal = () => {
        fetchData();
        setVisible(false);
    };
    
    
    const handleFileChange = async (e) => {
        let file = e.files[0];
        
        try {
            // when image is bigger than 300 x 300 then resize
            FileResizer.imageFileResizer(
                file,
                300, // Width
                300, // Height
                'PNG', // Format (e.g., JPEG, PNG)
                100, // Quality (0-100)
                0, // Rotation (degrees)
                async (resizedUri) => {
                    setSelectedFile(resizedUri);
                },
              'blob' // Output type ('blob' or 'base64')
            );
          
        } catch (error) {
            console.error('Error resizing image:', error);
        }
          
    };

    const onRemove = () => {
        setSelectedFile(null);
    };

    const UpdateProfileCondition = (updateFormData , formData) => {
        return selectedFile !== null ||
        updateFormData.firstName !== formData.firstName ||
        updateFormData.lastName !== formData.lastName ||
        updateFormData.email !== formData.email ||
        updateFormData.workplace !== formData.workplace ||
        updateFormData.agency !== formData.agency
    }

    const UpdateBasicCondition = (updateFormData , formData) => {
        return  updateFormData.tel !== formData.tel || updateFormData.birthday !== formData.birthday
    }

    const UpdateAddressCondition = (updateFormData , formData) => {
        return  updateFormData.address !== formData.address ||
                updateFormData.city !== formData.city ||
                updateFormData.country !== formData.country ||
                updateFormData.postalCode !== formData.postalCode 
    }

    const responseStatus = async (response) => {
        if (response.status === 201) {
            hideModal();
            data.count();
            await toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Add Info Successfully",
                life: 3000,
            });
        } else if (response.status === 200) {
            hideModal();
            data.count();
            await toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Update Info Successfully",
                life: 3000,
            });
        } else {
            await toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Update Info Fail",
                life: 3000,
            });
            hideModal();
        }
    };
    
    const confirmDialogProfile = async () => {
        let enteredName = updateFormData.firstName;
        let enteredSurname = updateFormData.lastName;
        let enteredEmail = updateFormData.email;
        let enteredWorkplace = updateFormData.workplace;
        let enteredAgency = updateFormData.agency;
        let enteredUserPic = updateFormData.user_pic; 
        
        let responseProfile;
        let profile = await apiGetProfile();

        // if the updateformdata not same value with formdata
        if (UpdateProfileCondition(updateFormData,formData)) {
            let foundProfileData = profile.data.find((item) => item.profile_template.template_name === "profile");
            const templateId = searchProfile?.id
            const formDataUserPic = new FormData();
            formDataUserPic.append("file", selectedFile);
            formDataUserPic.append(
                "file_access_policy",
                JSON.stringify({ profile_template_id: "969b40cb-629a-470e-89ac-5b9346d5ac7d" })
            );

            //if found profile data in api
            if (foundProfileData !== undefined && foundProfileData.data !== undefined) {
                const profileInfoId = foundProfileData?.id || null;
                
                const putInfoProfile = {
                    data: [
                        {
                            name: "first_name",
                            value: enteredName,
                        },
                        {
                            name: "last_name",
                            value: enteredSurname,
                        },
                        {
                            name: "email",
                            value: enteredEmail,
                        },
                        {
                            name: "workplace",
                            value: enteredWorkplace,
                        },
                        {
                            name: "agency",
                            value: enteredAgency,
                        }
                        
                    ],
                    index: 10,
                };

                //if user select pic file then push user_pic object array data
                if (selectedFile) {   
                    let UploadPic = await apiUploadFile(formDataUserPic);
                    enteredUserPic = UploadPic.data.file_id 
                    
                    putInfoProfile.data.push({
                        name: "user_pic",
                        value: enteredUserPic,
                    });
                }

                responseProfile = await apiSaveProfile(profileInfoId, putInfoProfile);
                responseStatus(responseProfile);
                onRemove()
               
            // else no profile data in api then create new profile data 
            } else {
              
                const addInfo = {
                    profile_template_id : templateId,
                    data: [
                        {
                            name: "first_name",
                            value: enteredName,
                        },
                        {
                            name: "last_name",
                            value: enteredSurname,
                        },
                        {
                            name: "email",
                            value: enteredEmail,
                        },
                        {
                            name: "workplace",
                            value: enteredWorkplace,
                        },
                        {
                            name: "agency",
                            value: enteredAgency,
                        }
                    ],
                };   

                if (selectedFile) {
                    let UploadPic = await apiUploadFile(formDataUserPic);
                    enteredUserPic = UploadPic.data.file_id 
                    
                    addInfo.data.push({
                        name: "user_pic",
                        value: enteredUserPic,
                    });
                }
                responseProfile = await apiAddProfile(addInfo);
                responseStatus(responseProfile)
                onRemove()
            }
        //else updateformdata same value as formdata
        } else {
            
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Please Put New Info",
                life: 3000,
            });
             
        }
    };

    const confirmDialogBasicInfo = async () => {
        let enteredTel = updateFormData.tel;
        let enteredBirthDay = updateFormData.birthday;
        let responseBasicInfo;
        let profile =  await apiGetProfile();
        if (UpdateBasicCondition( updateFormData , formData )) {
            let foundBasicInfoData = profile.data.find((item) => item.profile_template.template_name === "basic_information");
            const templateId = searchBasic?.id

            if (foundBasicInfoData !== undefined && foundBasicInfoData.data !== undefined) {
                const basicInfoId = foundBasicInfoData?.id || null;
                let selectedDatePut = dayjs(enteredBirthDay).subtract(543,"year")
                
                const putInfoBasic = {
                    data: [
                        {
                            name: "tel",
                            value: enteredTel,
                        },
                        {
                            name: "birthday",
                            value: selectedDatePut,
                        },
                    ],
                    index: 10,
                };
                
                responseBasicInfo = await apiSaveProfile(basicInfoId, putInfoBasic);       
                responseStatus(responseBasicInfo);
            } else {
                let selectedDatePut = dayjs(enteredBirthDay).subtract(543,"year")
                const addInfo = {
                    profile_template_id : templateId,
                    data: [
                        {
                        name: "tel",
                        value: enteredTel,
                        },
                        {
                        name: "birthday",
                        value: selectedDatePut,
                        },
                    ],
                };   
                responseBasicInfo = await apiAddProfile(addInfo);
                responseStatus(responseBasicInfo)
            }

        } else {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Please Put New Info",
                life: 3000,
            });      
        }
    };
    const confirmDialogAddress = async () => {
        let enteredAddress = updateFormData.address;
        let enteredCity = updateFormData.city;
        let enteredCountry = updateFormData.country;
        let enteredPostalCode = updateFormData.postalCode;
        
        let responseAddress;
        let profile = await apiGetProfile();
        if (UpdateAddressCondition( updateFormData , formData )) {
            let foundAddressData = profile.data.find((item) => item.profile_template.template_name === "address");
            const templateId = searchAddress?.id
        
            if (foundAddressData !== undefined && foundAddressData.data !== undefined) {
                const addressId = foundAddressData?.id || null;
                const putInfoAddress = {
                    data: [
                        {
                            name: "address",
                            value: enteredAddress,
                        },
                        {
                            name: "city",
                            value: enteredCity,
                        },
                        {
                            name: "country",
                            value: enteredCountry,
                        },
                        {
                            name: "postal_code",
                            value: enteredPostalCode,
                        },
                    ],
                    index: 10,
                };

                responseAddress = await apiSaveProfile(addressId, putInfoAddress);
                responseStatus(responseAddress);
            }else{
                const addInfo = {
                    profile_template_id : templateId,
                    data: [
                        {
                        name: "address",
                        value: enteredAddress,
                        },
                        {
                        name: "city",
                        value: enteredCity,
                        },
                        {
                        name: "country",
                        value: enteredCountry,
                        },
                        {
                        name: "postal_code",
                        value: enteredPostalCode,
                        },
                    ],
                };   
                responseAddress = await apiAddProfile(addInfo);
                responseStatus(responseAddress)
            }
        } else {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Please Put New Info",
                life: 3000,
            });
            
        } 
    };
    
    const footerTemplate =  () => {
        let label ;
        if (activeTab === 0) {
            label = "แก้ไขข้อมูลที่ทำงาน";
            return (
                <div className="button-group add-edu ml-2 mb-3 mt-3 ">
                    <Button label={label} onClick={confirmDialogProfile} className="p-button-upload mr-2" />
                    <Button label="ยกเลิก" onClick={hideModal} className="p-button-secondary" />
                </div>
            );
        } else if (activeTab === 1) {
            label = "แก้ไขข้อมูลทั่วไป" ;
            return (
                <div className="button-group add-edu ml-2 mb-3 mt-3 ">
                    <Button label={label} onClick={confirmDialogBasicInfo} className="p-button-upload mr-2" />
                    <Button label="ยกเลิก" onClick={hideModal} className="p-button-secondary" />
                </div>
            );
        } else if (activeTab === 2) {
            label = "แก้ไขข้อมูลที่อยู่" ;
            return (
                <div className="button-group add-edu ml-2 mb-3 mt-3 ">
                    <Button label={label} onClick={confirmDialogAddress} className="p-button-upload mr-2" />
                    <Button label="ยกเลิก" onClick={hideModal} className="p-button-secondary" />
                </div>
            );
        }
    };

  
  return (
    <>
        <Tooltip target='.icons-modal-text' position="bottom"/>
        <span 
            onClick={openModal}
            className="icons-modal-text"  
                data-pr-tooltip='Click to Edit'
                data-pr-position='right'
                data-pr-at='right+5 top'
                data-pr-my='left center-1'
            style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize:"15px",
            alignItems: "center",
            textAlign:"center",
            marginRight:"-20px"
            
            }}
        >
            แก้ไขข้อมูลผู้ใช้งาน 
            <BiEdit 
                className="icons-modal-text"  
                    data-pr-tooltip='Click to Edit'
                    data-pr-position='right'
                    data-pr-at='right+5 top'
                    data-pr-my='left center+5'
                style={{fontSize: "35px" , marginTop:"-10px"}}
            />
        </span>
        <Dialog
            header="โปรไฟล์"
            visible={visible}
            style={{  width: "30rem",  height:"40rem", background: "bg-image" }}
            modal
            onHide={hideModal}
            headerClassName="border-bottom black bg-image"
            footer={footerTemplate}
        >
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="ข้อมูลที่ทำงาน">
                    <div className="profile-image-select pl-5 pr-5">
                        <Image src={imageSrc} alt=""  width={200}  height={200} roundedCircle  />
                        <FileUpload      
                            name="fileUpload"
                            accept=".jpg,.svg,.png"
                            chooseLabel="เลือกรูป"
                            uploadLabel="Upload"
                            customUpload
                            auto
                            onSelect={handleFileChange}
                            ref={fileUploadRef}
                            onRemove={onRemove}
                        />
                        {/* <ImageUploader formData={formData} setFormData={setFormData} /> */}
                    </div>
                    <div className="p-5 flex flex-wrap gap-3 mb-4 border-bottom">
                        <div className="flex-auto font-bold block">
                            <label htmlFor="name">ชื่อ :</label>
                            <InputText
                                id="sidebar-profile-name"
                                className="w-full"
                                name="name"
                                value={updateFormData.firstName}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, firstName: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="surname">นามสกุล :</label>
                            <InputText
                                id="sidebar-profile-surname"
                                className="w-full"
                                name="surname"
                                value={updateFormData.lastName}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, lastName: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="email">อีเมล :</label>
                            <InputText
                                id="sidebar-profile-email"
                                className="w-full"
                                name="email"
                                value={updateFormData.email}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, email: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="workplace">สถานที่ทำงาน :</label>
                            <InputText
                                id="sidebar-profile-workplace"
                                name="workplace"
                                className="w-full"
                                value={updateFormData.workplace}
                                onChange={(e) =>
                                    setUpdateFormData({ ...updateFormData, workplace: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="agency">หน่วยงาน :</label>
                            <InputText
                                id="sidebar-profile-agency"
                                className="w-full"
                                name="agency"
                                value={updateFormData.agency}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, agency: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header="ข้อมูลทั่วไป">           
                    <div className="p-5 flex flex-wrap gap-3 border-bottom">
                        <div className="flex-auto font-bold block">
                            <label htmlFor="birthday">วันเกิด:</label>
                            <ThaiDatePicker
                                value={selectedDate}
                                onChange={handleDatePickerChange}
                                id="birthday-input"
                                clearable
                                header={{
                                    prevButtonIcon:<GrPrevious style={{fontSize:"13px"}}/> , 
                                    nextButtonIcon:<GrNext style={{fontSize:"13px"}}/> ,
                                    prevButtonClassName:"prev-birthday-btn",
                                    nextButtonClassName:"next-birthday-btn",
                                    monthSelectClassName:"month-birthday-select",
                                    yearSelectClassName:"year-birthday-select"
                                }}
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="tel">เบอร์โทรศัพท์ :</label>
                            <InputText
                                id="sidebar-profile-tel"
                                className="w-full"
                                name="tel"
                                value={updateFormData.tel}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, tel: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header="ข้อมูลที่อยู่">
                    <div className="p-5 flex flex-wrap gap-3 mb-3 border-bottom">
                        <div className="flex-auto font-bold block">
                            <label htmlFor="address">ที่อยู่:</label>
                            <InputText
                                id="address-info"
                                name="address"
                                className="w-full"
                                value={updateFormData.address}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, address: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="city">อำเภอ:</label>
                            <InputText
                                id="city-info"
                                name="city"
                                className="w-full"
                                value={updateFormData.city}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, city: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="country">จังหวัด:</label>
                            <InputText
                                id="country-info"
                                name="country"
                                className="w-full"
                                value={updateFormData.country}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, country: e.target.value })}
                                required
                            />
                        </div>
                        <div className="flex-auto font-bold block">
                            <label htmlFor="postalCode">รหัสไปรษณีย์:</label>
                            <InputText
                                id="postalCode-info"
                                name="postalCode"
                                className="w-full"
                                value={updateFormData.postalCode}
                                onChange={(e) => setUpdateFormData({ ...updateFormData, postalCode: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
      <Toast ref={toast} position="top-center" />
    </>
  );
}

export default ProfileModal;
