import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import EducationModal from './modal/educationinfo'
import InterestsModal from './modal/interestsinfo'
import './main_style.css'
import { FaGraduationCap, FaMedal, FaStar } from 'react-icons/fa'
import PersonalGoalsModal from './modal/personalgoals'
import Home from '../frame/home'
import './../../App.css'
import { apiGetProfile, apiGetProfileTemplate } from '../../services/api/profile'
import ProfileModal from './modal/profileinfo'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { apiGetInfoSkillmeo } from '../../lib/services/api/user'
import { apiGetImagePromiseProfile } from '../../services/api/certificate'
dayjs.extend(buddhistEra)
dayjs.locale('th')


function MyInformation() {
	const [basicInfo, setBasicInfo] = useState([])
	const [addressInfo, setAddressInfo] = useState([])
	const [profileInfo, setProfileInfo] = useState([])
	const [educationInfo, setEducationInfo] = useState([])
	const [personalGoalInfo, setPersonalGoalInfo] = useState([])
	const [interestInfo, setInterestInfo] = useState([])
	const [profileTemplate, setProfileTemplate] = useState([])
	const [countupdate, setCountUpdate] = useState(0)
	const [imageSrc, setImageSrc] = useState([]);
	const anotherpiccondition = JSON.parse(localStorage.getItem('user_info'))
	
	const countupdateincrease = () => {
		setCountUpdate(countupdate + 1)
	}
	
	const fetchData = async () => {
		try {
			let profile = await apiGetProfile()
			
			const basicItem = profile.data.find((item) => item.profile_template.template_name === 'basic_information')
			const addressItem = profile.data.find((item) => item.profile_template.template_name === 'address')
			const profileItem = profile.data.find((item) => item.profile_template.template_name === 'profile')
			const educationItem = profile.data.filter((item) => item.profile_template.template_name === 'education')
			const goalItem = profile.data.filter((item) => item.profile_template.template_name === 'personal_goal')
			const interestItem = profile.data.filter((item) => item.profile_template.template_name === 'interest')

			setBasicInfo(basicItem?.data)
			setAddressInfo(addressItem?.data)
			setProfileInfo(profileItem?.data)
			setEducationInfo(educationItem)
			setPersonalGoalInfo(goalItem)
			setInterestInfo(interestItem)
			
			let findsrc = profileItem.data?.find((item) => item.name === 'user_pic')
			
			let profileTemplate = await apiGetProfileTemplate()
			setProfileTemplate(profileTemplate.data)
			if(findsrc){
				let res = await apiGetImagePromiseProfile(findsrc?.value)
				setImageSrc(res)
			}
			await apiGetInfoSkillmeo()
		
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [countupdate])

	const getValueByName = (name) => {
		const item = basicInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

	const getAddressByName = (name) => {
		const item = addressInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

	const getProfileByName = (name) => {
		const item = profileInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

	let basicInfoData = {
		tel: getValueByName('tel'),
		email: getValueByName('email'),
		birthday: dayjs(getValueByName('birthday')),
		gender: getValueByName('gender'),
	}
	
	let addressInfoData = {
		address: getAddressByName('address'),
		city: getAddressByName('city'),
		country: getAddressByName('country'),
		postalCode: getAddressByName('postal_code'),
	}

	let profileData = {
		firstName: getProfileByName('first_name'),
		lastName: getProfileByName('last_name'),
		email: getProfileByName('email'),
		workplace: getProfileByName('workplace'),
		agency: getProfileByName('agency'),
		user_pic: getProfileByName('user_pic')
	}
	
	const birthdayDisplay = basicInfoData.birthday.isValid()
	? basicInfoData.birthday.locale('th').format('DD MMMM BBBB')
	: "";
	return (
		<>
			<Home />
			<Container className='bg-gray container-size' fluid>
				<Row>
					<Col className='profile-container'>
						{imageSrc !== null && imageSrc.length !== 0 ? (
							<Image src={imageSrc} width={300}  height={300} className='border-avatar_' alt='' roundedCircle />
							) : (anotherpiccondition?.title === "Ms." ? (
							<Image src='/user_girl.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
							) : (	
							<Image src='/user_boy.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
						))}
						<h5 className='text-color-1 avatar-text-upper'>
							ข้อมูลติดต่อ :{' '}
							<span className='text-dark fs-6'>
								{basicInfoData.tel} <br />
								<span>
									{profileData.email}{' '}
								</span>
							</span>
						</h5>
						<h5 className='text-color-1 mt-2 underline text-center'>
							<span>
								<ProfileModal profileInfo={profileData} profileTemplate={profileTemplate} basicInfo={basicInfoData} addressInfo={addressInfoData} count={countupdateincrease} />
							</span>
						</h5>
					</Col>
					<Col className='profile-group mt-4 mb-4'>
						<div className='border-warning  border-bottom'>
							<div className='d-flex'>
								<span>
									<h5 className='text-color-1'>ข้อมูลผู้ใช้งาน</h5>
								</span>
							</div>
							<p className='text-capitalize'>
								ชื่อ : {profileData.firstName} {profileData.lastName}
							</p>
							<p>สถานที่ทำงาน : {profileData.workplace}</p>
							<p>หน่วยงาน : {profileData.agency}</p>
						</div>
						<br />
						<div>
							<h5 className='text-color-1 mt-2 '>ข้อมูลส่วนตัว</h5>
							<p>วันเกิด : {birthdayDisplay}</p>
							<p>
								ที่อยู่ : {addressInfoData.address} {addressInfoData.city} {addressInfoData.country} {addressInfoData.postalCode}
							</p>
						</div>
					</Col>
				</Row>
			</Container>

			<Container className='bg-body-teritiary' fluid>
				<Row className='bg-image'>
					<Col className='p-0'>
						<Row className='text-center bg-white'>
							<Col>
								<div>
									<EducationModal educationInfo={educationInfo} profileTemplate={profileTemplate} count={countupdateincrease} />
									<h5>ประวัติการศึกษา</h5>
								</div>
							</Col>
						</Row>
						<Row className='content-interest bg-transparent'>		
							<Col>
								{educationInfo.length === 0 ? (
										<h5 className='text-color-5 text-center'>กรุณาเพิ่มข้อมูล</h5>
								) : (
									educationInfo.map((education, index) => (
										<div key={index} className='education-content'>
											{
												<div>
													<div style={{ marginLeft: '0' }}>
														<FaGraduationCap className='icons' />
														<span className='text-color-1 fw-bold '>{education.data.find((item) => item.name === 'degree_type').value + ' '}</span>
														{dayjs(education.data.find((item) => item.name === 'date_period_from').value).format('BBBB')}-{dayjs(education.data.find((item) => item.name === 'date_period_to').value).format('BBBB')}
													</div>
													<div style={{ marginLeft: '25px' }}>{education.data.find((item) => item.name === 'school_name').value}</div>
													<div style={{ marginLeft: '25px' }}>
														{education.data.find((item) => item.name === 'field_of_study').value}
														{/* {modifyEducationData(education, education.id)} */}
													</div>
												</div>
											}
										</div>
									))
								)}
							</Col>
						</Row>
					</Col>
					<Col className='p-0'>
						<Row className='text-center bg-white'>
							<Col>
								<div className='text-center justify-content-center'>
									<PersonalGoalsModal personalGoalInfo={personalGoalInfo} profileTemplate={profileTemplate} count={countupdateincrease} />
									<h5>เป้าประสงค์</h5>
								</div>
							</Col>
						</Row>
						<Row className='content-interest bg-transparent'>
							<Col>
								{personalGoalInfo.length === 0 ? (
									<h5 className='text-color-5 text-center'>กรุณาเพิ่มข้อมูล</h5>
								) : (
									personalGoalInfo.map((goal, index) => (
										<div key={index} className='goal-content'>
											<div>
												<div style={{ marginLeft: '0' }}>
													<FaMedal className='icons' />
													{goal.data.find((item) => item.name === 'title').value}
												</div>
												<div style={{ marginLeft: '25px' }}>{dayjs(goal.data.find((item) => item.name === 'target_date').value).format('DD MMMM BBBB')}</div>
											</div>
										</div>
									))
								)}
							</Col>
						</Row>
					</Col>
					<Col className='p-0'>
						<Row className='text-center bg-white'>
							<Col>
								<div className='text-center justify-content-center'>
									<InterestsModal interestInfo={interestInfo} profileTemplate={profileTemplate} count={countupdateincrease} />
									<h5>ความสนใจ</h5>
								</div>
							</Col>
						</Row>
						<Row className='content-interest bg-transparent'>
							<Col>
								{personalGoalInfo.length === 0 ? (
									<h5 className='text-color-5 text-center'>กรุณาเพิ่มข้อมูล</h5>
								) : (
									interestInfo.map((interest, index) => (
										<div key={index} className='education-content'>
											<div>
												<div style={{ marginLeft: '0' }}>
													<FaStar className='icons' />
													{interest.data.find((item) => item.name === 'interest').value}
												</div>
											</div>
										</div>
									))
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			<Container className='footer' fluid>
				<Row>
					<Col className='footer-item-left'>
						<Image src='/bg_footer2.png' className='justify-content-right align-self-left' width={400} height={320} alt='' fluid />
					</Col>
					<Col className='footer-item-right'>
						<Image src='/bg_footer1.png' className='justify-content-right align-self-right' width={400} height={320} alt='' fluid />
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default MyInformation
