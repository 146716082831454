import { Container, Row, Col, Image } from 'react-bootstrap'

export default function Footer() {
	return (
		<Container className='bg-white' fluid>
			<Row>
				<Col className='footer-item-left'>
					<Image src='/bg_footer2.png' className='justify-content-right align-self-left' width={400} alt='' fluid />
				</Col>
				<Col className='footer-item-right'>
					<Image src='/bg_footer1.png' className='justify-content-right align-self-right' width={400} alt='' fluid />
				</Col>
			</Row>
		</Container>
	)
}
