import instance from "./../../lib/config/skillmeoaxios";

const apiGetCategoryCert = async () => {
    try {
      const result = await instance.get(`/certificate/categories`, {
      });
  
      return result;
    } catch (error) {
      return error && error.response;
    }
};

const apiUploadFile = async (formdata) => {
    try {
      const result = await instance.post(`/user/file/upload`, formdata, {
      });
  
      return result;
    } catch (error) {
      return error && error.response;
    }
};

const apiAddCertificate = async (enteredInfo) => {
    try {
        const result = await instance.post(`/certificate/my`, enteredInfo, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiGetMyCertificate = async () => {
    try {
        const result = await instance.get(`/certificate/my`, {
        });

        return result;
    } catch (error) {
        return error && error.response;
    }
};

const apiDeleteCertificate = async (certificateId)=>{
    try {
        const response = await instance.delete(`/certificate/my/${certificateId}`)
        return response;
    } catch (error) {
        return error && error.response;
    }
};

const apiGetImagePromise = async (item) => {
    try {
        const response = await instance.get(`/user/file/download/${item.data.file_hash}`, {
        responseType: 'arraybuffer',
        });

        const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(imageBlob);

        return imageUrl;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};
const apiGetImagePromiseProfile = async (file_hash) => {
    try {
        const response = await instance.get(`/user/file/download/${file_hash}`, {    
        responseType: 'arraybuffer',
    });

        const imageBlob = new Blob([response.data], { type: 'image/png' });
        const imageUrl = URL.createObjectURL(imageBlob);

        return imageUrl;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};


  export {apiUploadFile ,apiAddCertificate , apiGetCategoryCert , apiGetMyCertificate , apiGetImagePromise , apiGetImagePromiseProfile , apiDeleteCertificate}