import axios from '../../lib/config/skillmeoaxios'

const apiGetUserListAll = async (keyword, sort_by, sort_order, page, per_page) => {
	try {
		const result = await axios.get(`/admin/user`, {
			params: {
				keyword: keyword,
				sort_by: JSON.stringify(sort_by),
				sort_order: JSON.stringify(sort_order),
				page: 1,
				per_page: 10,
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiAddUser = async (enteredInfo) => {
	try {
		const result = await axios.post(`/admin/user`, enteredInfo, {
		})

		return result
	} catch (error) {
		return error && error.response
	}
}

const apiDeleteUser = async (profileid) => {
	try {
		const result = await axios.delete(`/admin/user/${profileid}`, {
		})

		return result
	} catch (error) {
		return error && error.response
	}
}

const apiUpdateRole = async (userId, enteredRole) => {
	try {
		const result = await axios.put(`/admin/user/${userId}/role`, enteredRole, {
		})

		return result
	} catch (error) {
		return error && error.response
	}
}
export { apiGetUserListAll, apiAddUser, apiDeleteUser, apiUpdateRole }
