import React, { useState,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import './modal.css'
import { apiImportMoocConfirm, apiImportMoocUpload, apiImportSkillmeoConfirm, apiImportSkillmeoUpload, apiImportUserConfirm, apiImportUserUpload } from "../../../services/api/register";
function ImportUserModal({ addCounter , currentApp}) {
    const fileUploadRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
    const firstCondition = currentApp === "Skillmeo"
    const secondCondition = currentApp === "Mooc"
    const showDialog = () => {
        setVisible(true);
    };

    const hideDialog = () => {
        setVisible(false);
    };
   
    const showErrorMessage = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };
    
    const showSuccessMessage = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
        });
    };
    const handleFileUpload = async () => {
        const uploadedFiles = fileUploadRef.current.getFiles();
    
        if (uploadedFiles.length === 0) {
            showErrorMessage("Please Choose File");
            return;
        }
    
        let resupload = null;
        let apiUpload, apiConfirm;
    
        if (firstCondition) {
            apiUpload = apiImportSkillmeoUpload;
            apiConfirm = apiImportSkillmeoConfirm;
        } else if(secondCondition) {
            apiUpload = apiImportMoocUpload;
            apiConfirm = apiImportMoocConfirm;
        } else {
            apiUpload = apiImportUserUpload;
            apiConfirm = apiImportUserConfirm;
        }
    
        resupload = await apiUpload(uploadedFiles);
    
        if (resupload.status >= 200 && resupload.status <= 250 && resupload.data.import_user_records[0].is_validated === true) {
            const userfileid = resupload.data.import_user_file.id;
            const confirmrecordids = resupload.data.import_user_records.map((item) => item.id);
    
            const payload = {
                "import_user_file_id": userfileid,
                "confirm_user_record_ids": confirmrecordids,
            };
    
            const resconfirm = await apiConfirm(payload);
    
            if (resconfirm.status >= 200 && resconfirm.status <= 250) {
                addCounter();
                hideDialog();
                showSuccessMessage("Import New User Successfully");
                return;
            }
        }
        
        // Handle errors
        let errorMessage = "";

        if (resupload && resupload.statusCode >= 400) {
            errorMessage = resupload.message;
        } else {
            errorMessage = resupload?.data?.import_user_records[0]?.verify_log || "Unknown Error";
        }

        showErrorMessage(errorMessage)
    };

    return (
        <div>
            <Toast ref={toast} position="top-center"></Toast>
            <Button
                label="อิมพอร์ตผู้ใช้งาน"
                severity="success"
                className="btn-user"
                onClick={showDialog}
            />
            <Dialog
                header="อิมพอร์ตผู้ใช้งาน จาก CSV"
                headerClassName='border-bottom black bg-image'
                visible={visible}
                onHide={hideDialog}
                style={{ 
                    width: "60vw", 
                    maxWidth: "30rem", 
                    height: "25rem", 
                }}
                footer={ 
                    <div className='button-group ml-2 mb-2 mt-3 '>
                        <Button label='อิมพอร์ต' onClick={handleFileUpload} className='p-button-upload mr-2' />
                        <Button label='ยกเลิก' onClick={hideDialog} className='p-button-secondary' />
                    </div>
                }
            >
                <div className="p-field file-upload-btn">
                    <FileUpload
                    ref={fileUploadRef}
                    id="csvFile"
                    name="csvFile"
                    accept=".csv,.xlsx"
                    auto
                    chooseLabel="เลือกไฟล์"
                    uploadLabel="Upload"
                    cancelLabel="Cancel"
                    customUpload
                    className="file-choose-btn"
                    />
                </div>
            </Dialog>
        </div>
    );
}

export default ImportUserModal;
