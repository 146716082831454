import axios from '../../config/axios';
import axiosskillmeo from '../../config/skillmeoaxios';
import axiosmooc from '../../config/moocaxios'
const apiImportStudent = async (file) => {
    // console.log(file[0].name)
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/student/import_excel`, data ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportInstructor = async (file) => {
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/instructor/import_excel`, data ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const registerUser = async (payload) => {
    try {
        const result = await axios.post(`/user/register`, payload ,{ })
        return result
    } catch (error) {
        return error && error.response
    }
}

const apiImportUserUpload = async (file) => {
    var data = new FormData();
    data.append('file', file[0]);
    try {
        const result = await axios.post(`/user/import/upload`, data ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportUserConfirm = async (payload) => {
    try {
        const result = await axios.post(`/user/import/confirm`, payload ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportSkillmeoUpload = async (file) => {
    var data = new FormData();
    data.append('file', file[0]);
    try {
        const result = await axiosskillmeo.post(`/admin/user/import/upload`, data ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportSkillmeoConfirm = async (payload) => {
    try {
        const result = await axiosskillmeo.post(`/admin/user/import/confirm`, payload ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportMoocUpload = async (file) => {
    var data = new FormData();
    data.append('file', file[0]);
    try {
        const result = await axiosmooc.post(`/v1/user/import/upload`, data ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportMoocConfirm = async (payload) => {
    try {
        const result = await axiosmooc.post(`/v1/user/import/confirm`, payload ,{
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export {
    apiImportUserUpload,
    apiImportUserConfirm,
    apiImportStudent,
    apiImportInstructor,
    apiImportSkillmeoUpload,
    apiImportSkillmeoConfirm,
    apiImportMoocUpload,
    apiImportMoocConfirm, 
    registerUser
}