import Mainmenu from "./common/mainmenu";
import Home from "../frame/home";
import "./../../App.css";
import { Button, Dropdown } from "react-bootstrap";
import "./main_style.css";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { BiSolidHeartCircle } from "react-icons/bi";
import { IoArrowRedoCircleSharp } from "react-icons/io5";
import { IoIosSend } from "react-icons/io";
import { AiTwotoneSetting } from "react-icons/ai";


function StudentActivity() {
  return (
    <div>
      <Home />
      <div className="content-grid ">
        <div className="column-content">
          <Mainmenu />
          <div className="row-content">
            <div className="btn-create">
              <Button variant="success">Create New Student Activity</Button>
              <Button variant="success" className="btn-custom" style={{ marginLeft: "10px" }}>
                <AiTwotoneSetting />
              </Button>
            </div>

            <div className="dropdown-btn">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Activity
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Sort by
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="img-group">
            <Row xs={4} md={8} className="g-4">
              {Array.from({ length: 8 }).map((_, idx) => (
                <Col key={idx} className="col">
                  <Card className="custom-card" style={{ border: "2px solid green" }}>
                    <div className="img-position">
                      <Card.Img
                        variant="top"
                        src="holder.js/100px160"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                    <Card.Body className="social-icons">
                      <BiSolidHeartCircle />
                      <IoArrowRedoCircleSharp />
                      <IoIosSend />
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentActivity;
