import React, { useState, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { apiDeleteProfile, apiAddProfile, apiSaveProfile } from '../../../services/api/profile'
import { BiSolidMessageSquareAdd } from 'react-icons/bi'
import { Image } from 'primereact/image'
import { Toast } from 'primereact/toast'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'
import { Tooltip } from 'primereact/tooltip'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { DatePicker } from 'react-datepicker'
import { GrPrevious , GrNext } from "react-icons/gr";
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { ThaiDatePicker } from "thaidatepicker-react";
dayjs.extend(buddhistEra)
dayjs.locale('th')

function PersonalGoalsModal(data) {
	const [visible, setVisible] = useState(false)
	const [personalGoalData, setPersonalGoalData] = useState([])
	const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
	const [profileToDelete, setProfileToDelete] = useState(null)
	const [AddDialogVisible, setAddDialogVisible] = useState(false)
	const toast = useRef(null)
	let search = data.profileTemplate.find((item) => item.template_name === 'personal_goal')
	const [formData, setFormData] = useState({
		title: '',
		targetDate: dayjs().format("YYYY-MM-DD"),
	})
	
	addLocale('th', {
		firstDayOfWeek: 0,
		dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
		dayNamesShort: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
		dayNamesMin: ['อ', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
		monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
		monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
		today: 'วันนี้',
		clear: 'ล้าง',
	})

	useEffect(() => {
		return () => {
			const dataTable = data.personalGoalInfo.map((item) => ({
				id: item.id,
				profile_template: item.profile_template,
				template_name: item.profile_template.template_name,
				title: item.data.find((dataItem) => dataItem.name === 'title')?.value || '',
				target_date: dayjs(item.data.find((dataItem) => dataItem.name === 'target_date')?.value).format("BBBB-MM-DD") || '',
				index: item.index,
				created_at: item.created_at,
				updated_at: item.updated_at,
				deleted_at: item.deleted_at,
			}))
			setPersonalGoalData(dataTable)
		}
	}, [data.personalGoalInfo, data.profileTemplate])

	const updatePersonalGoalData = (updatedData) => {
		setPersonalGoalData(updatedData)
	}

	const handleAdd = async () => {
		const templateId = search.id

		if (!formData.title || !formData.targetDate) {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Please fill in all required fields.',
				life: 3000,
			})

			return
		}

		const addInfo = {
			profile_template_id: templateId,
			data: [
				{
					name: 'title',
					value: formData.title,
				},
				{
					name: 'target_date',
					value: formData.targetDate,
				},
			],
		}
		let response = await apiAddProfile(addInfo)

		if (response.status === 201 || response.status === 200) {
			console.log('Successfully added/edited:', response)

			setFormData({
				title: '',
				targetDate: '',
			})

			toast.current.show({
				severity: 'success',
				summary: 'Added',
				detail: 'Added',
				life: 3000,
			})

			data.count()
			setAddDialogVisible(false)
		} else {
			console.error('Error:', response)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'error',
				life: 3000,
			})
		}
	}

	const onRowEditComplete = async (e) => {
		// newData is old data 
		const isEditAction = e.data !== e.newData
		if (isEditAction) {
			let selectedDate = e.data.target_date
			const updateInfo = {
				data: [
					{
						name: 'title',
						value: e.newData.title,
					},
					{
						name: 'target_date',
						value: dayjs(selectedDate).subtract(543,"year"),
					},
				],
			}
			await apiSaveProfile(e.data.id, updateInfo)
				.then((response) => {
					if (response.status === 201 || response.status === 200) {
						console.log('Saved successfully:', response.data.data)

						const updatedData = personalGoalData.map((row) => {
							if (row.id === e.data.id) {
								return {
									...row,
									title: e.newData.title,
									target_date: selectedDate,
								}
							}
							return row
						})

						updatePersonalGoalData(updatedData)

						toast.current.show({
							severity: 'success',
							summary: 'Success',
							detail: 'Save successfully',
							life: 3000,
						})
						data.count()
					}
				})
				.catch((error) => {
					console.error('Error while saving:', error)
					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: 'error',
						life: 3000,
					})
				})
		}
	}

	const handleDelete = (profileId) => {
		setProfileToDelete(profileId)
		setConfirmDialogVisible(true)
	}

	const confirmDelete = async () => {
		try {
			await apiDeleteProfile(profileToDelete)
			toast.current.show({
				severity: 'success',
				summary: 'Deleted',
				detail: 'Deleted',
				life: 3000,
			})

			const updatedData = personalGoalData.filter((item) => item.id !== profileToDelete)
			updatePersonalGoalData(updatedData)
			data.count()
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'error',
				life: 3000,
			})
		} finally {
			setConfirmDialogVisible(false)
			setProfileToDelete(null)
		}
	}

	const textEditor = (options) => {
		return (
			<span className='p-float-label'>
				<InputText type='text' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
			</span>
		)
	}

	const dateEditor = (options) => {
		
		return (
			<span className='p-float-label'>
				<ThaiDatePicker 
					id='target-date'
					inputId='target_date' 
					value={dayjs(options.value).subtract(543,"year").format("YYYY-MM-DD")}  
					onChange={(e) => options.editorCallback(dayjs(e).add(543,"year").format("YYYY-MM-DD"))}
					clearable={false}
					header={{
						prevButtonIcon:<GrPrevious style={{fontSize:"13px"}}/> , 
						nextButtonIcon:<GrNext style={{fontSize:"13px"}}/> ,
						prevButtonClassName:"prev-birthday-btn",
						nextButtonClassName:"next-birthday-btn",
						monthSelectClassName:"month-birthday-select",
						yearSelectClassName:"year-birthday-select"
					}}
				/>
			</span>
		)
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon='pi pi-trash' rounded outlined severity='danger' onClick={() => handleDelete(rowData.id)} />
			</React.Fragment>
		)
	}

	const addModal = async () => {
		setAddDialogVisible(true)
	}

	return (
		<>
			<Tooltip target='.goal-image' />
			<Image
				src='/personal_goal.png'
				onClick={() => setVisible(true)}
				className='goal-image text-color-1 fs-3'
				width={150}
				alt=''
				style={{ cursor: 'pointer' }}
				data-pr-tooltip='Click to Edit'
				data-pr-position='right'
				data-pr-at='right+5 top'
				data-pr-my='left center-2'
			/>
			<Dialog
				contentClassName='personal-goal-content'
				header='เป้าประสงค์'
				visible={visible}
				onHide={() => setVisible(false)}
				style={{ width: '80vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				headerClassName=' border-bottom black bg-image'>
				<div className='card p-fluid'>
					<DataTable value={personalGoalData} editMode='row' dataKey='id' onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50vw'}}>
						<Column key={'title'} field={'title'} header={'Title'} editor={(options) => textEditor(options)} style={{ width: '20%' ,height:'100$' }}></Column>
						<Column key={'target_date'} field={'target_date'} header={'Target Date'} editor={(options) => dateEditor(options)} style={{ width: '20%' }}></Column>

						<Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
						<Column body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
					</DataTable>
					<div>
						<span onClick={addModal} className='cursor-pointer'>
							<BiSolidMessageSquareAdd className='pi pi-plus text-color-1 fs-5' /> Add
						</span>
					</div>
				</div>
			</Dialog>
			<Dialog
				header='เพิ่มเป้าประสงค์'
				headerClassName='header-add-edu  border-bottom black bg-image'
				headerStyle={{ borderBottom: '1px solid black' }}
				visible={AddDialogVisible}
				onHide={() => setAddDialogVisible(false)}
				style={{ width: '40vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				footer={
					<div className='button-group'>
						<Button
							label='เพิ่ม'
							className='p-button-text add-edu-confirm'
							onClick={() => {
								handleAdd()
								setAddDialogVisible(false)
							}}
						/>
						<Button
							label='ยกเลิก'
							className='p-button-text add-edu-cancel'
							onClick={() => {
								setAddDialogVisible(false)
								setFormData({
									title: '',
									targetDate: '',
								}) // Clear the form fields
							}}
						/>
					</div>
				}>
				<div className='p-fluid add-edu'>
					<div className='p-field'>
						<label htmlFor='title'>title:</label>
						<InputText id='title-info' name='title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required />
					</div>
					<div className='p-field'>
						<label htmlFor='targetDate'>target date:</label>
						<ThaiDatePicker 
							id='target-date'
							inputId='target_date' 
							value={formData.targetDate}  
							onChange={(e) => setFormData({ ...formData , targetDate: e})}
							clearable
							header={{
								prevButtonIcon:<GrPrevious style={{fontSize:"13px"}}/> , 
								nextButtonIcon:<GrNext style={{fontSize:"13px"}}/> ,
								prevButtonClassName:"prev-birthday-btn",
								nextButtonClassName:"next-birthday-btn",
								monthSelectClassName:"month-birthday-select",
								yearSelectClassName:"year-birthday-select"
							}}
						/>
					</div>
				</div>
			</Dialog>
			<ConfirmDialog
				contentClassName='confirm-dialog'
				headerClassName='header-add-edu  border-bottom black bg-image'
				visible={confirmDialogVisible}
				onHide={() => setConfirmDialogVisible(false)}
				message='ท่านแน่ใจหรือไม่ ที่จะลบเป้าประสงค์นี้?'
				style={{ width: '30vw' }}
				header='ลบเป้าประสงค์'
				icon='pi pi-exclamation-triangle'
				accept={confirmDelete}
				reject={() => setConfirmDialogVisible(false)}
				position='top'
				footer={
					<div className='button-group mt-3'>
						<Button label='ใช่' icon='pi pi-check' onClick={confirmDelete} className='p-button-text add-edu-confirm' />
						<Button label='ไม่' icon='pi pi-times' onClick={() => setConfirmDialogVisible(false)} className='p-button-text add-edu-cancel' />
					</div>
				}
			/>
			<Toast ref={toast} position='bottom-right'></Toast>
		</>
	)
}

export default PersonalGoalsModal
