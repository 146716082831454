import React, { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import CertificateModal from './modal/uploadcert.jsx'
import Home from '../frame/home.jsx'
import './../../App.css'
import { apiDeleteCertificate, apiGetCategoryCert, apiGetImagePromise, apiGetImagePromiseProfile, apiGetMyCertificate } from '../../services/api/certificate.js'
import { apiGetProfile } from '../../services/api/profile.js'
import { Dropdown } from 'primereact/dropdown'
import Footer from '../frame/footer-skilmeo.jsx'
import { Galleria } from 'primereact/galleria'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

function CourseCertificate() {
	const [showModal, setShowModal] = useState(false)
	const [activeIndex, setActiveIndex] = useState(0)
	const [categories, setCategories] = useState([])
	const [listcate, setListCate] = useState([])
	const [imageData, setImageData] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [counter, setCounter] = useState(0)
	const [profileInfo, setProfileInfo] = useState([])
	const [imageSrc, setImageSrc] = useState([]);
	const [displayConfirm, setDisplayConfirm] = useState(false);
	const [itemIdToDelete, setItemIdToDelete] = useState(null);
	const toast = useRef(null)
	const anotherpiccondition = JSON.parse(localStorage.getItem('user_info'))
    
	const handleSelect = (event) => {
		const value = event.value.category_name;
		if (value === 'All') {
		  setSelectedCategory(null); 
		  setActiveIndex(0)
		} else {
		  setSelectedCategory(value); 
		  setActiveIndex(0)
		}
	  };

	const closeModal = () => {
		setShowModal(false)
	}
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				let profile = await apiGetProfile()

				const profileItem = profile.data.find((item) => item.profile_template.template_name === 'profile')

				let findsrc = profileItem.data?.find((item) => item.name === 'user_pic')

				setProfileInfo(profileItem?.data)
				let res = await apiGetImagePromiseProfile(findsrc?.value)
				setImageSrc(res)

				const categoryResponse = await apiGetCategoryCert()
				const listcateResponse = await apiGetMyCertificate()
				const categoryData = categoryResponse.data
				const listcateData = listcateResponse.data

				setCategories(categoryData)
				setListCate(listcateData)
				

				const imagePromises = listcateData.map(async (item) => {
					return await apiGetImagePromise(item); 
				});

				const imageDataArray = await Promise.all(imagePromises)
				setImageData(imageDataArray)
				setLoading(false)
			} catch (error) {
				console.error('Error fetching data:', error)
			}
		}

		fetchData()
	}, [counter])

	const incrementCounter = () => {
		setCounter(counter + 1)
	}

	const filteredListcate = selectedCategory ? listcate.filter((item) => item.certificate_category.category_name === selectedCategory) : listcate

	const filteredImageData = selectedCategory ? imageData.filter((_, index) => listcate[index]?.certificate_category.category_name === selectedCategory) : imageData

	const getProfileByName = (name) => {
		const item = profileInfo?.find((item) => item.name === name)
		return item ? item.value : ''
	}

    const responseStatus = async (detail,response) => {
		if (response.status === 200) {
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: `${detail} Successfully`,
				life: 3000,
			});
			incrementCounter();
		} else {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: `${detail} Fail`,
				life: 3000,
			});
		}
	}

	let profileData = {
		firstName: getProfileByName('first_name'),
		lastName: getProfileByName('last_name'),
		email: getProfileByName('email'),
		workplace: getProfileByName('workplace'),
		agency: getProfileByName('agency'),
	}

	const itemTemplate = (item) => {
		return (
			<div className="image-item">	
			     <div className="p-galleria-item">
					<div className="p-galleria-item-img-container">
						<img src={item?.src} alt={item?.alt}  style={{ width: '100%', height: '550px', objectFit: 'cover' ,marginTop:"50px" }} />
						<div className="p-galleria-item-close" onClick={() => handleDeleteConfirmation(item.item)}>
							<span className="galleria-delete-icon pi pi-times" ></span>
						</div>
					</div>
				</div>
				<h5 className='text-color-5 my-5 mr-3' style={{display:"flex",justifyContent:"center"}}>Certificate: {selectedCategory ? selectedCategory : "All Category"}</h5>
			</div>
		);
	};

	const thumbnailTemplate = (item) => {
        return <img src={item?.src} alt={item?.alt} style={{ width: '100%',padding:"30px" ,display: 'block', }} />
    }

	const handleDeleteConfirmation = (item) => {
        setItemIdToDelete(item.id);
        setDisplayConfirm(true);
    };

    const confirmDelete = async () => {
		try {
			let response = await apiDeleteCertificate(itemIdToDelete)
			responseStatus('Delete Certificate',response)
			setDisplayConfirm(false);
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: `Delete Certificate Error`,
				life: 3000,
			})
		}
    };

    const cancelDelete = () => {
        setDisplayConfirm(false);
    };
	return (
		<>
			<Home />
			<Container className='bg-gray' fluid>
				<Row className=''>
					<Col className='text-center '>
						{imageSrc !== null && imageSrc.length !== 0 ? (
							<Image src={imageSrc} width={300}  height={300} className='border-avatar_ ' alt='' roundedCircle />
							) : (anotherpiccondition?.title === "Ms." ? (
							<Image src='/user_girl.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
							) : (	
							<Image src='/user_boy.png' width={300} className='border-avatar_' alt='' roundedCircle fluid />
						))}
					</Col>
					<Col className='profile-group'>
						<div className='border-warning  border-bottom mt-3'>
							<h5 className='text-color-5'>ข้อมูลใบประกาศนียบัตร</h5>
							<p className='text-capitalize'>
								ชื่อ : {profileData.firstName} {profileData.lastName}
							</p>
							<p>หน่วยงาน: {profileData.agency}</p>
						</div>

						<div className='d-flex mt-4 mb-2 ml-0'>
							<div className=''>
								{' '}
								<CertificateModal incrementCounter={incrementCounter} />
							</div>
							<h5 className='add-cert text-color-5 mr-5 ml-3'>เพิ่มใบประกาศนียบัตร</h5>
						</div>
					</Col>
				</Row>
			</Container>
			<Container className='cert-group bg-body-teritiary header-cert'fluid>
				{loading ? (
					<Row className='spinner-loading'>
						<Spinner animation='border' variant='success' style={{ width: '4rem', height: '4rem' }} />
					</Row>
				) : (listcate !== null && listcate.length !== 0 ?(
					<div className='slide-content'>
						<div className='text-category-dropdown d-flex text-center justify-content-end '>
							<div className='mt-5 d-flex'>
								<h5 className='text-color-5 mt-1 mr-3'>แสดงผลตามประเภท :</h5>
								<Dropdown
									optionLabel='category_name'
									value={selectedCategory}
									options={[{ category_name: 'All' }, ...categories]} 
									onChange={handleSelect}
									placeholder={selectedCategory ? selectedCategory : '---All---'}
									className='w-full md:w-12rem h-2rem '
								/>
							</div>
						</div>
						<Galleria
							value={filteredListcate.map((item, index) => ({
								src: filteredImageData[index] || '/path/to/placeholder-image.jpg',
								alt: `Certificate ${index + 1}`,
								item: item,
							}))}
							item={itemTemplate}
							thumbnail={thumbnailTemplate}
							activeIndex={activeIndex}
							onItemChange={(e) => setActiveIndex(e.index)}
							numVisible={7}
							showItemNavigators
							showItemNavigatorsOnHover
							showThumbnailNavigators
							responsiveOptions={[
								{
									breakpoint: '1024px',
									numVisible: 5,
									numScroll: 1,
								},
								{
									breakpoint: '768px',
									numVisible: 3,
									numScroll: 1,
								},
							]}
						/>
					</div>
					):(
						<Row className='text-color-5 text-center p-5'>
							<h5>ไม่พบใบประกาศนียบัตร กรุณาเพิ่ม</h5>
						</Row>
					))}
          	</Container>
        	{showModal && <CertificateModal closeModal={closeModal} />}
      		<Footer />
			<ConfirmDialog
                visible={displayConfirm}
                onHide={() => setDisplayConfirm(false)}
                message="คุณต้องการที่จะลบใบประกาศนียบัตรนี้ใช่หรือไม่?"
                header="ลบใบประกาศนียบัตร"
				headerClassName='border-bottom black bg-image'
                acceptClassName="p-button-danger"
				footer={
					<div>
						<div className='button-group ml-2 mb-2 mt-3 '>
							<Button label='ใช่' onClick={confirmDelete} className='p-button-upload mr-2' />
							<Button label='ไม่' onClick={cancelDelete} className='p-button-secondary' />
						</div>
					</div>
				}
            />
			<Toast ref={toast} position='top-center' />
    	</>
  );
}

export default CourseCertificate
