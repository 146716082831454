import { useEffect } from 'react'
import { apiLoginWithAuthCode } from '../../lib/services/api/auth'
import { apiGetInfoSkillmeo } from '../../lib/services/api/user'


function LoginWithAuthCode() {
	useEffect(() => {
		async function fetchData() {
			const urlParams = new URLSearchParams(window.location.search)
			const authorizeCode = urlParams.get('authorize_code')

			const jwtToken = urlParams.get('token')

			if (jwtToken) {
				localStorage.setItem('token', jwtToken)
			}

			if (authorizeCode) {
				try {
					const response = await apiLoginWithAuthCode({
						authorize_code: authorizeCode,
					})
					
					const { token, token_expired_at, refresh_token, refresh_token_expired_at } = response.data
					if (response.status === 201) {
						console.log('Success', response)
						
						localStorage.setItem('skillmeo_token', token)
						localStorage.setItem('skillmeo_expired', token_expired_at)
						localStorage.setItem('skillmeo_refresh_token', refresh_token)
						localStorage.setItem('skillmeo_refresh_token_expired_at', refresh_token_expired_at)
						
						const result = await apiGetInfoSkillmeo();
						if (result.status === 200) {
							localStorage.setItem('portfolio_user_info', JSON.stringify(result.data));
							window.location.replace('/infoboard');
						} else {
							console.error('Failed to fetch user info from Skillmeo');
						}
					} else {
						console.error('Error', response)
					}
				} catch (error) {
					console.error('An error occurred', error)
				}
			} else {
				console.error('Authorization Code not found in the URL.')
				window.location.replace(process.env.REACT_APP_MAIN_URL)

			}
		}
		fetchData()
	}, []);
	return null; 
}
export default LoginWithAuthCode
