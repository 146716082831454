import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { apiDeleteProfile, apiAddProfile, apiSaveProfile } from '../../../services/api/profile'
import { BiSolidMessageSquareAdd } from 'react-icons/bi'
import { Image } from 'primereact/image'
import { Tooltip } from 'primereact/tooltip'
import { ConfirmDialog } from 'primereact/confirmdialog'
import dayjs from 'dayjs'

export default function EducationModal(data) {
	let search = data.profileTemplate.find((item) => item.template_name === 'education')
	const [visible, setVisible] = useState(false)
	const [educationData, setEducationData] = useState([])
	const [AddDialogVisible, setAddDialogVisible] = useState(false)
	const toast = useRef(null)
	const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
	const [profileToDelete, setProfileToDelete] = useState(null)

	const [formData, setFormData] = useState({
		title: '',
		targetDate: '',
	})

	useEffect(() => {
		return () => {
			const dataTable = data.educationInfo.map((item) => ({
				id: item.id,
				profile_template: item.profile_template,
				template_name: item.profile_template.template_name,
				school_name: item.data.find((dataItem) => dataItem.name === 'school_name')?.value || '',
				degree_type: item.data.find((dataItem) => dataItem.name === 'degree_type')?.value || '',
				field_of_study: item.data.find((dataItem) => dataItem.name === 'field_of_study')?.value || '',
				date_period_from: dayjs(item.data.find((dataItem) => dataItem.name === 'date_period_from')?.value).add(543, 'year').format('YYYY')|| '',
				date_period_to: dayjs(item.data.find((dataItem) => dataItem.name === 'date_period_to')?.value).add(543, 'year').format('YYYY')|| '',
				index: item.index,
				created_at: item.created_at,
				updated_at: item.updated_at,
				deleted_at: item.deleted_at,
			}))
			setEducationData(dataTable)
		}
	}, [data.educationInfo, data.profileTemplate])

	const updateEducationData = (updatedData) => {
		setEducationData(updatedData)
	}

	const handleConfirmation = async () => {
		const templateId = search.id

		if (!formData.schoolName || !formData.degreeType || !formData.fieldOfStudy || !formData.datePeriodFrom || !formData.datePeriodTo) {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Please fill in all required fields.',
				life: 3000,
			})

			return
		}
		const formattedFromDate = formData.datePeriodFrom.getFullYear().toString()
		const formattedToDate = formData.datePeriodTo.getFullYear().toString()

		const addInfo = {
			profile_template_id: templateId,
			data: [
				{
					name: 'school_name',
					value: formData.schoolName,
				},
				{
					name: 'degree_type',
					value: formData.degreeType,
				},
				{
					name: 'field_of_study',
					value: formData.fieldOfStudy,
				},
				{
					name: 'date_period_from',
					value: formattedFromDate,
				},
				{
					name: 'date_period_to',
					value: formattedToDate,
				},
			],
		}
		let response = await apiAddProfile(addInfo)

		if (response.status === 201 || response.status === 200) {
			console.log('Successfully added/edited:', response)
			setFormData({
				schoolName: '',
				degreeType: '',
				fieldOfStudy: '',
				datePeriodFrom: '',
				datePeriodTo: '',
			})
			data.count()
			setAddDialogVisible(false)
		} else {
			console.error('Error:', response)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: response,
				life: 3000,
			})
		}
	}

	const onRowEditComplete = async (e) => {
		const isEditAction = e.data !== e.newData
		if (isEditAction) {
			const updateInfo = {
				data: [
					{
						name: 'school_name',
						value: e.newData.school_name,
					},
					{
						name: 'degree_type',
						value: e.newData.degree_type,
					},
					{
						name: 'field_of_study',
						value: e.newData.field_of_study,
					},
					{
						name: 'date_period_from',
						value: dayjs(e.newData.date_period_from).subtract(543, 'year').format('YYYY'),
					},
					{
						name: 'date_period_to',
						value: dayjs(e.newData.date_period_to).subtract(543, 'year').format('YYYY'),
					},
				],
			}
			await apiSaveProfile(e.data.id, updateInfo)
				.then((response) => {
					if (response.status === 200) {
						toast.current.show({ severity: 'success', summary: 'Success', detail: 'Saved successfully', life: 3000 })
					}
					data.count()
					const updatedData = educationData.map((item) => (item.id === e.newData.id ? { ...item, ...e.newData } : item))
					updateEducationData(updatedData)
				})
				.catch((error) => {
					console.error('Error while saving:', error)
					toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 })
				})
		}
	}

	const handleDelete = (profileId) => {
		setProfileToDelete(profileId)
		setConfirmDialogVisible(true)
	}
	const confirmDelete = async () => {
		try {
			await apiDeleteProfile(profileToDelete)
			toast.current.show({
				severity: 'success',
				summary: 'Deleted',
				detail: 'Deleted',
				life: 3000,
			})

			const updatedData = educationData.filter((item) => item.id !== profileToDelete)
			updateEducationData(updatedData)
			data.count()
		} catch (error) {
			console.error('An error occurred:', error)
			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'error',
				life: 3000,
			})
		} finally {
			setConfirmDialogVisible(false)
			setProfileToDelete(null)
		}
	}

	const textEditor = (options) => {
		return <InputText type='text' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon='pi pi-trash' rounded outlined severity='danger' onClick={() => handleDelete(rowData.id)} />
			</React.Fragment>
		)
	}

	const addModal = async () => {
		setAddDialogVisible(true)
	}

	const yearEditor = (options) => {
		console.log(options)
		const yearDate = new Date(parseInt(options.value, 10), 0, 1)
		return (
			<Calendar
				value={yearDate}
				onChange={(e) => {
					options.editorCallback(e.value.getFullYear().toString()) // Get the year part from the date
				}}
				view='year'
				dateFormat='yy'
			/>
		)
	}

	return (
		<>
			<Tooltip target='.edu-image' />
			<Image
				src='/education.png'
				onClick={() => setVisible(true)}
				className='edu-image text-color-1 fs-3'
				width={150}
				alt=''
				style={{ cursor: 'pointer' }}
				data-pr-tooltip='Click to Edit'
				data-pr-position='right'
				data-pr-at='right+5 top'
				data-pr-my='left center-2'
			/>

			<Dialog
				header='ประวัติการศึกษา'
				visible={visible}
				onHide={() => setVisible(false)}
				style={{ width: '80vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				headerClassName=' border-bottom black bg-image'>
				<div className='card p-fluid'>
					<DataTable value={educationData} editMode='row' dataKey='id' onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
						<Column key={'school_name'} field={'school_name'} header={'School Name'} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
						<Column key={'degree_type'} field={'degree_type'} header={'Degree Type'} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
						<Column key={'field_of_study'} field={'field_of_study'} header={'Field of Study'} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
						<Column key={'date_period_from'} field={'date_period_from'} header={'From'} editor={(options) => yearEditor(options)} style={{ width: '20%' }}></Column>
						<Column key={'date_period_to'} field={'date_period_to'} header={'To'} editor={(options) => yearEditor(options)} style={{ width: '20%' }}></Column>
						<Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
						<Column body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }} className='text-center'></Column>
					</DataTable>
					<div>
						<span onClick={addModal} className='cursor-pointer'>
							<BiSolidMessageSquareAdd className='pi pi-plus text-color-1 fs-5' /> Add
						</span>
					</div>
				</div>
			</Dialog>

			<Dialog
				header='เพิ่มประวัติการศึกษา'
				headerClassName='header-add-edu border-bottom black bg-image'
				headerStyle={{ borderBottom: '1px solid black' }}
				visible={AddDialogVisible}
				onHide={() => setAddDialogVisible(false)}
				style={{ width: '80vw' }}
				breakpoints={{ '960px': '75vw', '641px': '100vw' }}
				footer={
					<div className='button-group'>
						<Button
							label='เพิ่ม'
							className='p-button-text add-edu-confirm'
							onClick={() => {
								handleConfirmation() // Call your confirmation logic
								setAddDialogVisible(false) // Close the dialog
							}}
						/>
						<Button
							label='ยกเลิก'
							className='p-button-text add-edu-cancel'
							onClick={() => {
								setAddDialogVisible(false)
								setFormData({
									schoolName: '',
									degreeType: '',
									fieldOfStudy: '',
									datePeriodFrom: '',
									datePeriodTo: '',
								}) // Clear the form fields
							}}
						/>
					</div>
				}>
				<div className='p-fluid add-edu'>
					<div className='p-field'>
						<label htmlFor='schoolName'>School Name:</label>
						<InputText id='schoolName-info' name='schoolName' value={formData.schoolName} onChange={(e) => setFormData({ ...formData, schoolName: e.target.value })} required />
					</div>
					<div className='p-field'>
						<label htmlFor='degreeType'>Degree Type:</label>
						<InputText id='degreeType-info' name='degreeType' value={formData.degreeType} onChange={(e) => setFormData({ ...formData, degreeType: e.target.value })} required />
					</div>
					<div className='p-field'>
						<label htmlFor='fieldOfStudy'>Field of Study:</label>
						<InputText
							id='fieldOfStudy-info'
							name='fieldOfStudy'
							value={formData.fieldOfStudy}
							onChange={(e) => setFormData({ ...formData, fieldOfStudy: e.target.value })}
							required
						/>
					</div>
					<div className='p-field'>
						<label>Date Period From:</label>
						<Calendar
							id='datePeriodFrom-info'
							name='datePeriodFrom'
							value={formData.datePeriodFrom}
							view='year'
							dateFormat='yy'
							onChange={(e) => setFormData({ ...formData, datePeriodFrom: e.value })}
						/>
					</div>
					<div className='p-field'>
						<label>To:</label>
						<Calendar
							id='datePeriodTo-info'
							name='datePeriodTo'
							value={formData.datePeriodTo}
							view='year'
							dateFormat='yy'
							onChange={(e) => setFormData({ ...formData, datePeriodTo: e.value })}
						/>
					</div>
				</div>
			</Dialog>
			<ConfirmDialog
				contentClassName='confirm-dialog'
				headerClassName='header-add-edu  border-bottom black bg-image'
				visible={confirmDialogVisible}
				onHide={() => setConfirmDialogVisible(false)}
				message='ท่านแน่ใจหรือไม่ ที่จะลบประวัติการศึกษานี้?'
				style={{ width: '30vw' }}
				header='ลบประวัติการศึกษา'
				icon='pi pi-exclamation-triangle'
				accept={confirmDelete}
				reject={() => setConfirmDialogVisible(false)}
				position='top'
				footer={
					<div className='button-group add-edu mt-3'>
						<Button label='ใช่' icon='pi pi-check' onClick={confirmDelete} className='p-button-text add-edu-confirm' />
						<Button label='ไม่' icon='pi pi-times' onClick={() => setConfirmDialogVisible(false)} className='p-button-text add-edu-cancel' />
					</div>
				}
			/>
			<Toast ref={toast} position='bottom-right'></Toast>
		</>
	)
}
