import lodash from 'lodash'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import MyInformation from './components/pages/myinfo'
import CourseCertificate from './components/pages/coursecert'
import Evidence from './components/pages/evidence'
import StudentActivity from './components/pages/stuactivity'
import SkillChart from './components/pages/skillchart'
import MyPortfolio from './components/pages/portfolio'
import UserManual from './lib/components/archives/user_manual'
import Settings from './components/auth/setting'
import LoginWithAuthCode from './components/auth/authlogin'
import Login from './components/auth/login'
import UserList from './components/pages/admin/user_list'
const dayjs = require('dayjs')

const App = () => {
	// Validate if token is expired
	let expired_at = localStorage.getItem('skillmeo_expired')
	if (expired_at && dayjs().isAfter(dayjs(expired_at))) {
		localStorage.clear()
	}

	let user_info = null
	let is_admin = false
	if (localStorage.getItem('user_info')) {
		try {
			user_info = JSON.parse(localStorage.getItem('user_info'))
			console.log('user_info', user_info)

			let chk_role = lodash.filter(user_info.roles, { id: 1 })
			if (chk_role.length > 0) is_admin = true
		} catch (e) {}

		console.log('is_admin : ', is_admin)
	}
	return (
		<Router>
			<Routes>
				{!localStorage.getItem('skillmeo_token') ? (
					<>
						<Route path={'/sso'} element={<LoginWithAuthCode />} />
						<Route path={'/'} element={<Login />} />
						<Route path={'/login'} element={<Login />} />
					</>
				) : (
					<>
						<Route path={'/'} element={<Login />} />
						<Route path={'/sso'} element={<LoginWithAuthCode />} />
						<Route path={'/infoboard'} element={<MyInformation />} />
						<Route path={'/course-certificate'} element={<CourseCertificate />} />
						<Route path={'/evidence-skill'} element={<Evidence />} />
						<Route path={'/student-activity'} element={<StudentActivity />} />
						<Route path={'/skill-chart'} element={<SkillChart />} />
						<Route path={'/my-portfolio'} element={<MyPortfolio />} />
						<Route path={'/admin-user'} element={<UserList />} />

						<Route path={'/usermanual'} element={<UserManual />} />
						<Route path={'/setting'} element={<Settings />} />

					</>
				)}
			</Routes>
		</Router>
	)
}
export default App
